.make-app {
  .slides-container {
    .slides-grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &::after {
        content: "";
        flex: auto;
      }

      .grid-item-slide {

        -webkit-user-select: none !important;
        -webkit-touch-callout: none !important;

        overflow: hidden;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        width: 200px;
        height: 200px;
        margin: 10px;

        position: relative;

        -webkit-box-shadow: 2px 2px 4px 0px rgba(34, 60, 80, 0.2);
        -moz-box-shadow: 2px 2px 4px 0px rgba(34, 60, 80, 0.2);
        box-shadow: 2px 2px 4px 0px rgba(34, 60, 80, 0.2);

        &.grid-item-slide-text {
          /*background-size: contain;*/
        }

        .drag-pressed {
          display: none;
          z-index: 12;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgb(255 255 255 / 72%);
          i {
            font-size: 10vw;
            color: #919191;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 5px 4px 0px 5px;
          }
        }

        @media (hover: none) {
          &.chosen {
            -webkit-box-shadow: 4px 4px 6px 4px rgba(34, 60, 80, 0.4);
            -moz-box-shadow: 4px 4px 6px 4px rgba(34, 60, 80, 0.4);
            box-shadow: 4px 4px 6px 4px rgba(34, 60, 80, 0.4);
          }
          &.chosen .drag-pressed {
            display: block;
          }
        }

        .upload-error-container {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          .upload-error {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-image:url('../../../images2/upload_failed.png');
            background-repeat: no-repeat;
            background-size: contain;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 10px 10px 60px 10px;
            text-align: center;
            .local-file-name,
            .upload-error-text {
              overflow-wrap: break-word;
            }
            .upload-error-text {
              font-weight: bold;
              padding-bottom: 10px;
            }
            .local-file-name {
              max-height: 70px;
              overflow: hidden;
              text-overflow: ellipsis;
              line-height: 15px;
            }
          }
        }

        & > img {
          opacity: 0;
          position: absolute;
          z-index: 11;
          top: 0;
          width: 100%;
          vertical-align: bottom;
        }

        &.loaded > img {
          opacity: 0;
          display: none;
        }

        &.loaded:hover, &.loaded.hover {
          .over {
            background: #ffffff70;
            opacity: 1;
          }
        }

        @media (hover: none) {
          .over {
            display: block !important;
            opacity: 1 !important;
            .control {
              border: 1px solid #ffffff4a !important;
              background: #ffffff80 !important;
              .fa {
                font-size: 12px !important;
              }
              &.zoomIn {
                display: none !important;
              }
              &.rotate {
                bottom: 3%;
                left: 3%;
                padding: 2px 6px 2px 7px;
              }
              &.edit {
                bottom: 3%;
                left: 3%;
                padding: 2px 6px 2px 7px;
                background: #ffffff !important;
                opacity: 1 !important;
              }
              &.delete {
                bottom: 3%;
                right: 3%;
                padding: 2px 6px 2px 7px;
              }
              &.move {
                top: 3%;
                right: 3%;
                padding: 2px 6px 2px 7px;
              }
            }
          }
        }

        .over {
          z-index: 11;
          opacity: 0;
          -webkit-transition: opacity 0.2s linear;
          -moz-transition: opacity 0.2s linear;
          -o-transition: opacity 0.2s linear;
          transition: opacity 0.2s linear;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          .control {
            background: #ffffffaa;
            border-radius: 10px;
            position: absolute;
            opacity: 0.8;
            cursor: pointer;
            -webkit-transition: all 0.2s linear;
            -moz-transition: all 0.2s linear;
            -o-transition: all 0.2s linear;
            transition: all 0.2s linear;

            &:hover {
              background: #ffffffee;
              opacity: 1;

              &.delete {
                color: #9d0000;
              }

              &.zoomIn {
                color: #2772ff;
              }

              &.rotate {
                color: #229d00;
              }
              &.edit {
                color: #0040af;
              }

              &.move {
                color: #229d00;
              }
            }
          }

          .zoomIn {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 6px 6px 1px 6px;
          }

          .fa {
            font-size: 18px;
          }

          .rotate {
            bottom: 9%;
            left: 9%;
            padding: 6px 6px 1px 6px;
          }

          .edit {
            bottom: 9%;
            left: 9%;
            padding: 6px 6px 1px 6px;
            background: #ffffff !important;
            opacity: 1 !important;
            color: #0040af;
          }

          .delete {
            bottom: 9%;
            right: 9%;
            padding: 6px 6px 1px 6px;
          }

          .move {
            top: 9%;
            right: 9%;
            padding: 6px 6px 1px 6px;
          }
        }
      }
    }

    .add-slide-item {
      width: 200px;
      height: 200px;
      margin: 10px;
      cursor: pointer;
      color: #666;
      border: 2px dashed #bbb;
      background: #ddd;
      position: relative;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      -webkit-transition: background 0.2s linear;
      -moz-transition: background 0.2s linear;
      -o-transition: background 0.2s linear;
      transition: background 0.2s linear;

      &:hover {
        color: #7D5777;
        border-color: #D6BFD3;
        background-color: #eeeeee;
      }

      .plusChar {
        font-size: 33px;
        font-weight: bold;
      }

      .text {
        padding-top: 10px;
        font-size: 12px;
        text-align: center;
      }

      .delete-all-slides {
        -webkit-transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        transition: all 0.2s linear;

        position: absolute;
        bottom: 0;
        background: #ebdcdc;
        padding: 3px 7px 0;
        border: 1px solid #e5a9a9;
        border-bottom: none;
        border-radius: 5px 5px 0 0;
        color: #a94f4f;
        &:hover:not(.disabled) {
          background: #f9f2f2;
          color: #d54343;
        }
        .text {
          padding-left: 5px;
          font-size: 11px;
        }
        &.disabled {
          color: #ab8383;
          border-color: #c7bebe;
        }
      }
    }

    .add-slide-item,
    .grid-item-slide {
      @include breakpoint-xl(max) {
        width: 17vw !important;
        height: 17vw !important;;
      }
      @include breakpoint-lg2(max) {
        width: 18vw !important;;
        height: 18vw !important;;
      }
      @include breakpoint-lg(max) {
        width: 21vw !important;;
        height: 21vw !important;;
      }
      @include breakpoint-md2(max) {
        width: 21vw !important;;
        height: 21vw !important;;
      }
      @include breakpoint-md(max) {
        width: 28vw !important;;
        height: 28vw !important;;
      }
      @include breakpoint-sm(max) {
        width: 27vw !important;;
        height: 27vw !important;;
      }
      @include breakpoint-sm2(max) {
        width: 42vw !important;;
        height: 42vw !important;;
      }
      @include breakpoint-sm3(max) {
        width: 39vw !important;;
        height: 39vw !important;;
      }
    }
  }
}