.make-app {
  .themes-container {
    .themes-grid {
      display: flex;
      flex-wrap: wrap;

      .ril__image {
        max-width: 50%;
      }

      .theme-item {
        position: relative;
        width: 25%;
        padding: 10px;

        //@include breakpoint-xl(max) {
        //  width: 25%;
        //}
        //@include breakpoint-lg(max) {
        //  width: 33%;
        //}
        //@include breakpoint-md(max) {
        //  width: 50%;
        //}
        //@include breakpoint-sm(max) {
        //  width: 100%;
        //}
        @include breakpoint-xl(max) {
          width: 33%;
        }
        @include breakpoint-lg(max) {
          width: 33%;
        }
        @include breakpoint-md(max) {
          width: 50%;
        }
        @include breakpoint-sm(max) {
          width: 100%;
        }

        & > div {
          border: none;
          cursor: pointer;
          -webkit-box-shadow: 2px 2px 4px 0px rgba(34, 60, 80, 0.2);
          -moz-box-shadow: 2px 2px 4px 0px rgba(34, 60, 80, 0.2);
          box-shadow: 2px 2px 4px 0px rgba(34, 60, 80, 0.2);
        }

        &.active {
          & > div {
          }
        }
        .activeOverlay {
          display: none;
        }
        &.active .activeOverlay {
          pointer-events: none;
          display: block;
          position: absolute;
          top: 10px;
          left: 10px;
          bottom: 10px;
          right: 10px;
          background: #ffffff80;
          border: 3px solid #a75a9b;
          text-align: center;
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: center;
          -webkit-box-shadow: 0px 0px 40px -1px rgba(167, 90, 155, 1) inset;
          -moz-box-shadow: 0px 0px 40px -1px rgba(167, 90, 155, 1) inset;
          box-shadow: 0px 0px 40px -1px rgba(167, 90, 155, 1) inset;
          .fa {
            pointer-events: none;
            color: #a75a9b;
            font-size: 45px;
            padding-bottom: 20px;
            text-shadow: rgb(255, 255, 255) 1px 0px 0px, rgb(255, 255, 255) 0.540302px 0.841471px 0px, rgb(255, 255, 255) -0.416147px 0.909297px 0px, rgb(255, 255, 255) -0.989992px 0.14112px 0px, rgb(255, 255, 255) -0.653644px -0.756802px 0px, rgb(255, 255, 255) 0.283662px -0.958924px 0px, rgb(255, 255, 255) 0.96017px -0.279415px 0px, rgb(255 255 255) 0px 0px 12px;
          }
          .text {
            pointer-events: none;
            color: #a75a9b;
            font-weight: bold;
            font-size: 16px;
            text-shadow: rgb(255, 255, 255) 1px 0px 0px, rgb(255, 255, 255) 0.540302px 0.841471px 0px, rgb(255, 255, 255) -0.416147px 0.909297px 0px, rgb(255, 255, 255) -0.989992px 0.14112px 0px, rgb(255, 255, 255) -0.653644px -0.756802px 0px, rgb(255, 255, 255) 0.283662px -0.958924px 0px, rgb(255, 255, 255) 0.96017px -0.279415px 0px, rgb(255 255 255) 0px 0px 7px;
          }

          @include breakpoint-lg(max) {
            .fa {
              font-size: 5vw;
              padding-bottom: 2vw;
            }
            .text {
              font-size: 2.2vw;
            }
          }
          @include breakpoint-md(max) {
            .fa {
              font-size: 7vw;
              padding-bottom: 3vw;
            }
            .text {
              font-size: 3.5vw;
            }
          }
          @include breakpoint-sm(max) {
            .fa {
              font-size: 13vw;
              padding-bottom: 6vw;
            }
            .text {
              font-size: 6vw;
            }
          }
        }


        //.video-container {
        //  display: none;
        //  @include breakpoint-sm(max) {
        //    display: block !important;
        //  }
        //}

        .image-container {
          display: block;
          position: relative;
          @include breakpoint-sm(max) {
            //display: none;
            & > img {
              width: 100%;
            }
          }

          & > img {
            display: none;
            opacity: 0;
          }

          &.loaded > img {
            display: block !important;
            opacity: 1;
            -webkit-transition: opacity 0.3s ease-in-out;
            -moz-transition: opacity 0.3s ease-in-out;
            -o-transition: opacity 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out;
          }
        }

        //.description-container {
        //  display: none;
        //  @include breakpoint-sm(max) {
        //    display: block !important;
        //  }
        //}


        .index {
          left: 12px;
          top: 12px;
        }
      }
    }
  }
}