@import "tabs/nav";
@import "tabs/common";

@import "tabs/themes";
@import "tabs/slides";
@import "tabs/music";
@import "tabs/payment";
@import "tabs/my-video";

@import "popup/theme";
@import "popup/slide";
@import "popup/swal";
@import "popup/add-slide";
@import "common/loader-ball";
@import "common/loader-horizontal";

@import "common/progress-line-animated";
@import "common/styled-slider-range";
@import "common/card-checkbox";
@import "common/on-boarding";



.page-make-section {
  overflow: initial;
  padding-top: 20px;

  .container {
    @include breakpoint-lg(max) {
      padding: 0;
      max-width: initial;
    }
  }
}

.imageFitContainer {
  max-width: 100%;
  max-height: 100%;
}

.imageFit {
  max-width: 100%;
  height: auto;
}

.error-boundary {
  color: #c41717;
  font-size: 12px;
  .fa {
    padding-right: 7px;
  }
}

