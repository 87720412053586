.make-app {
  .autoplay-allowed-checker {
    width: 1px;
    height: 1px;
    opacity: 0;
    video {
      width: 1px;
      height: 1px;
      opacity: 0;
    }
  }
  .tabs-top-sticky-empty{
    position: absolute;
    top: 20px;
    width: 10px;
    height: 20px;
    background: transparent;
    z-index: 200;
  }
  .tabs-bottom-sticky-empty {
    width: 10px;
    height: 10px;
    position: relative;

    &.themes {
      bottom: -57px;
    }
    &.slides {
      bottom: -57px;
    }
    &.music {
      bottom: -57px;
    }

    @include breakpoint-sm(max) {
      &.themes {
        bottom: -40px;
      }
      &.slides {
        bottom: -72px;
      }
      &.music {
        bottom: -72px;
      }
    }
  }

  //.tabs {
  //  .nav-tabs {
  //    .nav-link {
  //      transition: none !important;
  //    }
  //  }
  //}


  .tab-pane {
    min-height: 600px;
  }

  .footer-buttons {
    position: sticky;
    z-index: 100;
    bottom: 0;
    left: 0;
    right: 0;
    // нижняя плашка с кнопками прыгает на мобильном, когда скрывается address bar
    // ничего не помогло, сделал, чтобы address bar
    //bottom: env(safe-area-inset-bottom);
    //bottom: calc((100vh - 100%));
    background: #fff;
    .row {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    &.slides, &.music {
      .row-buttons {
        @include breakpoint-sm(max) {
          .text-left {
            white-space: nowrap;
          }
        }
      }
    }
    &.sticky-active {
      position: fixed;
      filter: drop-shadow(0px -3px 4px rgba(0, 0, 0, 0.40));

      &:before {
        content: "";
        background-color: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        right: calc((100vw - 100%) / -2);
        left: calc((100vw - 100%) / -2);
      }
      padding-top: 8px;
      .row {
        margin-left: 15px !important;
        margin-right: 15px !important;
        @include breakpoint-lg(max) {
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }
      .nav-item {
        margin-bottom: 2px;
        button.nav-link {
          padding-top: 27px;
          padding-bottom: 4px;
          &:before {
            font-size: 22px;
          }
          .step-number {
            margin: -2px 36px auto auto;
          }
        }
      }
    }
    .next {
      margin-right: 10px;
      margin-bottom: 10px;
    }
    .prev {
      margin-left: 10px;
      background-color: #8f989f!important;
      border-color: #8f989f!important;
      margin-bottom: 10px;
    }
    .question {
      background: none !important;
      border: none !important;
      color: #8f989f;
      font-size: 1.8rem !important;
      padding-left: 10px !important;
      padding-right: 10px !important;
      margin-left: 20px !important;
      @include breakpoint-lg(max) {
        font-size: 1.5rem !important;
      }
      @include breakpoint-sm3(max) {
        margin-left: 5px !important;
      }
      &:hover {
        color: #a75a9b !important;
      }
      &:focus, &:visited, &:active {
        color: #8f989f !important;
      }
    }
  }
  .index {
    height: 20px;
    background: #fff;
    position: absolute;
    left: 2px;
    top: 2px;
    border-radius: 7px;
    padding: 0px 6px;
    text-align: center;
    font-size: 12px;
    line-height: 19px;
    font-weight: bold;
    opacity: 0.5;
    color: #6f4e6a;
    border: 1px solid #ad7fa6;
  }

  .video-duration-top {
    display: none;
    padding-bottom: 7px;
    padding-right: 25px;
    span {
      white-space: nowrap;
    }
    @include breakpoint-sm(max) {
      display: initial;
    }
  }
  .video-duration-right-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .video-duration-right {
      display: initial;
      padding-bottom: 10px;
      padding-right: 10px;
      line-height: 16px;
      span {
        white-space: nowrap;
      }
      @include breakpoint-sm(max) {
        display: none;
      }
    }
  }
}
