.make-app {
  .nav {
    flex-wrap: nowrap;
    justify-content: center;
    border-bottom: none;
    background: #ffffff;
    padding-top: 20px;
    position: sticky;
    top: 0;
    z-index: 101;
    padding-bottom: 4px;
    &.on-boarding-active {
      position: unset;
    }
    .nav-item {
      button.nav-link {
        padding-bottom: 8px;
      }
    }
    &.sticky-active:not(.on-boarding-active) {
      filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.30));

      &:before {
        content: "";
        background-color: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        right: calc((100vw - 100%) / -2);
        left: calc((100vw - 100%) / -2);
      }
      ////padding-top: 8px;
      //top: -12px;
      //.nav-item {
      //  margin-bottom: 2px;
      //  button.nav-link {
      //    padding-top: 27px;
      //    padding-bottom: 4px;
      //    &:before {
      //      font-size: 22px;
      //    }
      //    .step-number {
      //      margin: -2px 36px auto auto;
      //    }
      //  }
      //}
    }

    .nav-item {
      button {
        outline: none !important;
        background: none;
        border-bottom: 2px solid transparent;
        position: relative;
        padding-top: 30px;
      }
      .tab-item {
        .step-number {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: -8px 28px auto auto;
          .step-inner {
            background: #fff;
            width: 20px;
            height: 20px;
            display: inline-block;
            border-radius: 10px;
            font-size: 11px;
            font-weight: bold;
            color: #787878 !important;
            border: 2px solid #787878;
            line-height: 16px;
          }
        }
        &:before {
          color: #787878 !important;
          font-size: 24px;
          font-weight: 900;
          font-family: 'Font Awesome 6 Free';
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          display: inline-block;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          line-height: 1;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
        &:after {
          content: "\f105";
          color: #c9c9c9cc !important;
          font-size: 17px;
          font-weight: 900;
          font-family: "Font Awesome 6 Free";
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          display: inline-block;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          line-height: 1;
          position: absolute;
          top: 20px;
          right: -20px;
          @media (max-width: 576px) {
            right: -20px;
          }
          @media (max-width: 510px) {
            right: -12px;
          }
          @media (max-width: 445px) {
            right: -7px;
          }
          @media (max-width: 385px) {
            right: -2px;
          }
          @media (max-width: 325px) {
            display: none;
          }
          margin: auto;
        }
        &.active {
          &:before {
            color: #a75a9b !important;
          }
          .step-number {
            .step-inner {
              color: #a75a9b !important;
              border: 2px solid #a75a9b;
            }
          }
        }
      }
      //&:not(:first-of-type) {
        .tab-item {
          margin-left: 30px;
          @media (max-width: 576px) {
            margin-left: 10px;
            padding-left: 40px;
            padding-right: 40px;
          }
          @media (max-width: 510px) {
            margin-left: 10px;
            padding-left: 30px;
            padding-right: 30px;
          }
          @media (max-width: 445px) {
            margin-left: 10px;
            padding-left: 20px;
            padding-right: 20px;
          }
          @media (max-width: 385px) {
            margin-left: 10px;
            padding-left: 15px;
            padding-right: 15px;
          }
          @media (max-width: 325px) {
            margin-left: 10px;
            padding-left: 10px;
            padding-right: 10px;
          }
          @media (max-width: 285px) {
            margin-left: 10px;
            padding-left: 5px;
            padding-right: 5px;
          }
          @media (max-width: 245px) {
            margin-left: 10px;
            padding-left: 0;
            padding-right: 0;
          }
          //@include breakpoint-sm(max) {
          //  margin-left: -10px;
          //}
        //}
      }
      .tab-item {
        //@media (max-width: 576px) {
        //  margin-left: 10px;
        //  padding-left: 20px;
        //  padding-right: 20px;
        //}
        //@include breakpoint-sm3(max) {
        //  margin-left: -10px;
        //  padding-left: 10px;
        //  padding-right: 10px;
        //}
        span.lg {
          display: initial;
        }
        span.sm {
          display: none;
        }
        @include breakpoint-sm(max) {
          span.lg {
            display: none;
          }
          span.sm {
            display: initial;
          }
        }
      }
      &:last-of-type {
        .tab-item:after {
          content: "";
        }
      }
      .tab-themes {
        &:before {
          content: "\f008";
        }
      }
      .tab-slides {
        &:before {
          content: "\f03e";
        }
      }
      .tab-music {
        &:before {
          content: "\f001";
        }
      }
      .tab-done {
        &:before {
          content: "\f058";
        }
        &:after {
          content: none;
        }
      }
      .tab-my-video {
        display: none;
      }
    }
  }
  .active-tab-music .nav.sticky-active {
    filter: none!important;
  }
}
button.btn.btn-primary.button-tab-my-video-absolute {
  position: fixed;
  right: 0;
  top: 18%;
  z-index: 102 !important;

  padding: 17px 8px 17px 18px !important;
  height: initial !important;
  line-height: 18px !important;
  text-align: center;
  text-transform: none;
  background-color: rgb(255 255 255 / 78%) !important;
  color: $color-theme !important;
  letter-spacing: initial !important;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-box-shadow: -2px -2px 4px 0px rgba(34, 60, 80, 0.2) !important;
  -moz-box-shadow: -2px -2px 4px 0px rgba(34, 60, 80, 0.2) !important;
  box-shadow: -2px -2px 4px 0px rgba(34, 60, 80, 0.2) !important;
  font-weight: normal;
  font-size: 15px !important;

  &.active {
    background-color: #a75a9bc2 !important;
    color: #fff !important;
  }

  &:hover, &.active:hover {
    padding: 17px 18px 17px 28px !important;
  }

  &:hover, &:active, &.active:hover, &.active:active {
    background-color: $color-theme !important;
    color: #fff !important;
  }
}