.make-app {
  .my-video-container {
    .my-video-grid {
      display: flex;
      flex-wrap: wrap;

      .ril__image {
        max-width: 50%;
      }

      .my-video-item {
        position: relative;
        width: 25%;
        padding: 10px;

        @include breakpoint-xl(max) {
          width: 33%;
        }
        @include breakpoint-lg(max) {
          width: 33%;
        }
        @include breakpoint-md(max) {
          width: 50%;
        }
        @include breakpoint-sm(max) {
          width: 100%;
        }

        & > div {
          border: none;
          cursor: pointer;
          -webkit-box-shadow: 2px 2px 4px 0px rgba(34, 60, 80, 0.2);
          -moz-box-shadow: 2px 2px 4px 0px rgba(34, 60, 80, 0.2);
          box-shadow: 2px 2px 4px 0px rgba(34, 60, 80, 0.2);
        }

        &.active {
          & > div {
          }
        }

        .image-container {
          display: block;
          position: relative;
          @include breakpoint-sm(max) {
            //display: none;
            & > img {
              width: 100%;
            }
          }

          & > img {
            display: none;
            opacity: 0;
          }

          &.loaded > img {
            display: block !important;
            opacity: 1;
            -webkit-transition: opacity 0.3s ease-in-out;
            -moz-transition: opacity 0.3s ease-in-out;
            -o-transition: opacity 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out;
          }

          .upload-error-container {
            display: none;
            width: 100%;
            height: 100%;
            .upload-error {
              width: 100%;
              height: 100%;
              background-image:url('../../../images2/upload_failed.png');
              background-repeat: no-repeat;
              background-size: contain;
              background-position: left;
            }
          }
          &.load-failed {
            .sl-loader-ball {
              visibility: hidden;
            }
            .upload-error-container {
              display: block;
              position: absolute;
              top: 0;
              bottom: 0;
              right: 0;
              left: 0;
            }
          }
        }

        .index {
          left: 12px;
          top: 12px;
        }

        .project-text {
          padding: 3px 20px;
        }
      }
    }
  }
}