.ril__navButtons {
  @include breakpoint-md(max) {
    top: -120px !important;
  }
  @include breakpoint-sm2(max) {
    padding: 30px 20px !important;
    top: -140px !important;
  }
  &:focus {
    outline: none;
  }
}

.sl-swal {
  &.swal2-container.swal2-backdrop-show {
    background: rgba(0, 0, 0, 0.85);
  }

  .swal2-popup {
    width: 70% !important;
    max-width: 600px;
    min-width: 320px;
    height: 60vh !important;
    max-height: 600px;
    min-height: 300px;

    @include breakpoint-md(max) {
      width: 85% !important;
      height: 80vh !important;
    }
    @include breakpoint-sm(max) {
      width: 100%!important;
      height: 90vh !important;
    }
  }

  :focus {
    outline: none !important;
    box-shadow: none !important;
  }

  h2 {
    font-size: 16px;
    font-weight: 500;
  }
}

.sl-swal-alert {
  &.how-get-discount {
    .swal2-html-container {
      line-height: 25px !important;
      a {
        text-decoration: underline;
      }
    }
  }
  &.swal2-container.swal2-backdrop-show {
    background: rgba(0, 0, 0, 0.85);
  }

  .swal2-popup {
    button.swal2-confirm {
      background-color: $color-theme !important;
    }
  }

  :focus {
    outline: none !important;
    box-shadow: none !important;
  }

  .swal2-icon.swal2-info {
    border-color: #A75A9BB5;
    color: $color-theme;
  }
}