.background-1 {
  background-color: #ffe6e6;
}
.background-2 {
  background-color: #e6ffef;
}
.background-3 {
  background-color: #e6f9ff;
}
.background-4 {
  background-color: #e6eaff;
}
.background-5 {
  background-color: #f5e6ff;
}
.background-6 {
  background-color: #ffe6f6;
}
.large-icon {
  font-size: 24px!important;
}

.ea-index-Page {
  td.actions {
    min-width: 97px;
  }
}

.ea-index-partners-videopic-page {
  td.batch-actions-selector {
    width: 34px;
  }
  td.actions {
    width: 110px;
  }
}
.ea-edit-partners-videopic-page {
  .ts-control {
    display: block;
  }
  .ts-wrapper.multi .ts-control>div {
    display: block;
    margin: 6px;
  }
}

.ea-index-partners-ubileyday-page {
  td.batch-actions-selector {
    width: 34px;
  }
  td.actions {
    width: 110px;
  }
}
.ea-edit-partners-ubileyday-page {
  .ts-control {
    display: block;
  }
  .ts-wrapper.multi .ts-control>div {
    display: block;
    margin: 6px;
  }

  .accordion-item {
    position: relative;
    &::before {
      content: "⋮⋮";
      position: absolute;
      left: -7px;
      top: -4px;
      font-weight: bold;
      letter-spacing: -10px;
      cursor: pointer;
      padding: 10px;
    }
    h2 {
      margin-left: 20px;
    }
  }
  .page-blocks-collection {
    legend {
      position: relative;
      &::after {
        content: "СМАЙЛЫ";
        position: absolute;
        right: 0;
        background: #990099;
        padding: 2px 20px;
        color: #fff;
        cursor: pointer;
      }
    }
    .accordion-button-text-1 {
      color: #000;
    }
    .accordion-button-text-2,
    .accordion-button-text-2:hover {
      margin-top: 0;
      padding-top: 0;
      font-size: 13px;
      color: #555;
    }
  }
}

.page-blocks-collection {
  textarea {
    field-sizing: content;
  }
}

.ea-edit-CardCategory {
  .accordion-item {
    position: relative;
    &::before {
      content: "⋮⋮";
      position: absolute;
      left: -7px;
      top: -4px;
      font-weight: bold;
      letter-spacing: -10px;
      cursor: pointer;
      padding: 10px;
    }
    h2 {
      margin-left: 20px;
    }
  }
  .field-collection-item {
    position: relative;
    .greeting-row-counter {
      position: absolute;
      left: -15px;
      font-size: 11px;
      color: #555555;
      top: 8px;
    }
  }
  .greetings-collection {
    legend {
      position: relative;
      &::after {
        content: "СМАЙЛЫ";
        position: absolute;
        right: 0;
        background: #990099;
        padding: 2px 20px;
        color: #fff;
        cursor: pointer;
      }
    }
    .accordion-button-text-1 {
      color: #000;
    }
    .accordion-button-text-2,
    .accordion-button-text-2:hover {
      margin-top: 0;
      padding-top: 0;
      font-size: 13px;
      color: #555;
    }
    textarea {
      field-sizing: content;
    }
  }
}

.ea-index-Card {
  td.actions {
    min-width: 92px;
  }
  .content-body {
    table:not(.youtube-cards) {
      counter-reset: rowNumber;
      tr {
        counter-increment: rowNumber;
        position: relative;
        td:first-child::before {
          content: counter(rowNumber);
          min-width: 1em;
          margin-right: 0.5em;
          position: absolute;
          top: 23px;
          left: -16px;
          font-size: 10px;
          color: #a3a3a3;
        }
      }
      tr:first-of-type td::before {
        counter-set: rowNumber 1;
      }
    }
  }
}

.form-widget textarea {
  height: 100px;
  width: 100%;
}

.ea-index-Theme,
.ea-index-Music
{
  table.datagrid {
    max-width: 700px;
    tr {
      cursor: move;
      td.actions {
        width: 60px;
      }
      td.field-image {
        width: 150px;
      }
    }
  }
}

.ea-index-SeoCategoryLink,
.ea-index-SeoCategoryText,
.ea-index-SeoCategoryTask
{
  table.datagrid {
    //max-width: 700px;
    tr {
      td.batch-actions-selector {
        width: 40px;
      }
      td.actions {
        width: 60px;
        white-space: nowrap;
      }
      td[data-label="ID"] {
        width: 70px;
      }
    }
  }
}

.ea-index-PromoCode
{
  table.datagrid {
    max-width: 700px;
    tr {
      td.actions {
        width: 100px;
        white-space: nowrap;
      }
    }
  }
}

.ea-index-Card {
  table.datagrid {
    tr {
      cursor: move;
    }
  }
}

.sl-card-project-data {
  display: flex;
  margin-bottom: 10px;
  .preview-id {
    width: 50%
  }
  .load-btn {
    padding-right: 32px;
    span {
      padding-right: 10px;
    }
    .fa-spinner {
      visibility: hidden;
    }
    &.loading {
      .fa-spinner {
        visibility: visible;
      }
    }
  }
}
.project-data-textarea {
  overflow: auto !important;
  height: 84px !important;
}

.card-index-categories-collection-button {
  font-weight: normal!important;
  text-decoration: none;
  white-space: nowrap !important;
}

.actions-footer {
  margin-top: 10px;
  margin-bottom: 40px;
  a, button {
    margin: 0 7px 0 8px;
  }
}

.sl-textarea-copy {
  display: flex;
  textarea {
    max-width: 300px;
    display: inline-block;
    max-height: 100px;
    overflow: auto !important;
  }
}
label[for=Card_youtubeVideoDownloadPath] {
  display: none;
}
.sl-card-download-video {
  a {
    width: 300px;
    padding: 15px 10px;
  }
}
label[for=Card_youtubeCoverDownloadPath] {
  display: none;
}
.sl-card-download-youtube-cover {
  a {
    background-color: #06b798 !important;
    width: 300px;
    padding: 15px 10px;
  }
}

#ea-index-SeoCategoryText {
  .action-downloadTxt {
    padding: 0 1px 1px 3px;
    i {
      font-size: 11px;
    }
  }
}

.ea-edit-SeoCategoryText #SeoCategoryText_metaDescription {
  height: 100px;
}

.length {
  &.grey {
    color: #555;
  }
  &.green {
    color: #009900
  }
  &.red {
    color: #cc0000;
  }
}

.umanit_easyadmintree_tree-item-label {
  &.bg-1 {
    background-color: #ffefef;
  }
  &.bg-2 {
    background-color: #f6f4ff;
  }
  &.bg-3 {
    background-color: #e6fffd;
  }
  &.bg-4 {
    background-color: #e6ffec;
  }
  &.bg-5 {
    background-color: #fffbe6;
  }
}
