//Avatar
.avatar {
  flex-shrink: 0;
  width: $spacer*1.8;
  height: $spacer*1.8;
  border-radius: 50%;

  .avatar-lg {
    width: $spacer*2;
    height: $spacer*2;
  }

  .avatar-sm {
    width: $spacer;
    height: $spacer;
  }
}
.avatar-xl {
  width: $spacer*6;
  height: $spacer*6;
}

.avatar-lg {
  width: $spacer*2;
  height: $spacer*2;
}

.avatar-sm {
  width: $spacer;
  height: $spacer;
}
