.react-joyride__overlay {
  z-index: 1100 !important;
}
.__floater.__floater__open {
  z-index: 1200 !important;
  transition: opacity 0.6s ease 0s, transform 0s, top 0s !important;
}
.react-joyride__tooltip button[data-test-id=button-skip] {
  word-break: break-word;
}
.react-joyride__tooltip {
  color: #624b5e !important;
  font-size: 1em !important;
  button {
    outline: none !important;
  }
  button[data-test-id="button-primary"] {
    background-color: #a75a9b !important;
    border-color: #a75a9b !important;
    color: #ffffff !important;
    font-family: "Ubuntu", sans-serif !important;
    border-radius: 5px 5px 5px 5px !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    letter-spacing: 0.5px !important;
    text-decoration: none !important;
    padding: 12px 18px !important;
    position: relative !important;
    border-width: 1px !important;
    border-style: solid !important;
    transition: all 0.3s !important;
    margin-bottom: 6px !important;
    line-height: 14px !important;
    cursor: pointer !important;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      height: 2px;
      bottom: 2px;
      background: #fff9;
      -webkit-transition: all 10s linear;
      -moz-transition: all 10s linear;
      -o-transition: all 10s linear;
      transition: all 10s linear;
    }
    &.autoplay {
      &:before {
        left: 100%;
      }
    }
  }
  button[data-test-id="button-back"] {
    background-color: #fff !important;
    border-color: #424446 !important;
    color: #424446 !important;
    font-family: "Ubuntu", sans-serif !important;
    border-radius: 5px 5px 5px 5px !important;
    font-size: 12px !important;
    font-weight: normal !important;
    letter-spacing: 0.5px !important;
    text-decoration: none !important;
    padding: 12px 18px !important;
    position: relative !important;
    border-width: 1px !important;
    border-style: solid !important;
    transition: all 0.3s !important;
    margin-bottom: 6px !important;
    line-height: 14px !important;
    cursor: pointer !important;
  }
  button[data-test-id="button-skip"] {
    font-family: "Ubuntu", sans-serif !important;
    font-size: 13px !important;
    color: #585858 !important;
    font-weight: normal !important;
    text-decoration: underline !important;
  }
}
