.ril-outer {
  @media screen and (orientation:landscape) {
    .ril__toolbar {
      background: none !important;
      pointer-events: none;
    }
  }
  .lightboxCustomContent {
    pointer-events: none;

    & > .themesPopupItem {
      .selectThemeButton {
        display: block;
        margin-top: 20px;
        pointer-events: all;
      }
      & > div {
        pointer-events: none;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 80% !important;
        @include breakpoint-md(max) {
          width: 100% !important;
        }
        height: initial !important;
        max-width: 900px !important;
        @include breakpoint-md(max) {
          max-width: 100% !important;
        }
        max-height: 80% !important;
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;

        & > video {
          width: 100% !important;
          height: auto !important;
          pointer-events: all
        }
        & > .selectThemeButton {
          @include breakpoint-md(max) {
            font-size: 13px !important;
            height: 50px !important;
            line-height: 36px !important;
            padding: 0px 50px !important;
            margin-top: 50px !important;
          }
        }

        @media screen and (orientation:landscape) and (hover: none) {
          height: 100% !important;
          width: initial !important;

          & > video {
            height: 100% !important;
            width: initial !important;
          }
          & > .selectThemeButton {
            position: absolute;
            top: 0;
            //left: 0;
            margin-top: 0 !important;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%)
          }
        }
      }

      &.vertical > div > video {
        width: auto !important;
        height: 100% !important;
      }
    }
  }
}