.card-checkbox {
  width: 110px;
  display: inline-block;
  margin-right: 40px;

  input[type="checkbox"] {
    -ms-transform: scale(2); /* IE */
    -moz-transform: scale(2); /* FF */
    -webkit-transform: scale(2); /* Safari and Chrome */
    -o-transform: scale(2); /* Opera */
    transform: scale(2);
  }
  label {
    padding-left: 10px;
  }
}