// Shop
.shop {
    position: relative;
}

.product {
    margin-bottom: 40px;
    position: relative;
    display: block;

    a:not(.btn) {
        color: $color-body;
    }

    .product-image {
        position: relative;
        width: 100%;
        height: auto;
        overflow: hidden;
    }

    .product-image>a,
    .product-image img {
        display: block;
        width: 100%;
        height: 100%;
    }

    .product-image>a,
    .product-wishlist a {
        -webkit-transition: all .4s ease;
        -o-transition: all .4s ease;
        transition: all .4s ease;
    }

    .product-image>a:nth-of-type(2) {
        top: 0;
        left: 0;
        opacity: 0;
        z-index: -1;
        position: absolute;
    }

    .product-image:hover>a:nth-of-type(2) {
        opacity: 1;
        z-index: 2;
    }

    .product-wishlist a {
        border: 2px solid #333;
        border-radius: 50%;
        height: 30px;
        left: 12px;
        line-height: 28px;
        position: absolute;
        text-align: center;
        top: 12px;
        width: 30px;
        z-index: -1;
        opacity: 0;
    }

    &:hover {

        .product-wishlist,
        .product-wishlist a {
            z-index: 3;
            opacity: 1;
        }
    }

    .product-description {
        padding: 8px 0 10px;
    }

    .product-title {
        max-width: 80%;

        h3,
        h3 a {
            font-size: 18px;
            margin-bottom: 0;
        }
    }

    .product-category {
        display: block;
        opacity: 0.5;
    }

    .product-price {
        width: 26%;
        float: right;
        margin-bottom: 0;
        margin-top: -26px;
        text-align: right;

        ins {
            color: #444;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 4px;
            text-decoration: none;
        }

        del {
            display: block;
            font-size: 12px;
            font-weight: 400;
            margin: -20px 0 -4px;
            opacity: 0.6;
        }
    }

    .product-rate {
        height: 23px;
        float: left;
        color: #FFC300;
    }

    .product-reviews {

        &,
        a {
            color: #73848e;
            float: right;
            font-size: 13px;
        }
    }

    .product-sale,
    .product-new,
    .product-hot,
    .product-out-stock {
        border-radius: 50%;
        color: #ffffff;
        font-size: 11px;
        font-weight: 600;
        height: 42px;
        right: 10px;
        line-height: 42px;
        position: absolute;
        text-align: center;
        text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.14);
        top: 10px;
        width: 42px;
        z-index: 5;
    }

    .product-sale,
    .product-sale-off {
        background: #ffc300;
    }

    .product-new {
        background: #00c0e9;
    }

    .product-hot {
        background: #d6284b;
    }

    .product-out-stock {
        background: #a5a5a5 none repeat scroll 0 0;
        border-radius: 4px;
        padding: 0 18px;
        width: auto;
    }

    .product-sale-off {
        border-radius: 12px 0 0;
        bottom: 0px;
        color: #ffffff;
        font-size: 16px;
        font-weight: 600;
        height: 42px;
        line-height: 42px;
        position: absolute;
        right: 0px;
        text-align: center;
        text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.14);
        width: 88px;
        z-index: 5;
    }

    .product-overlay {
        position: absolute;
        width: 100%;
        height: 44px;
        line-height: 44px;
        top: auto;
        bottom: -44px;
        left: 0;
        z-index: 6;
        transition: bottom .3s ease;

        a {
            transition: bottom .3s ease;
            color: #fff;
            display: block;
            font-size: 13px;
            background-color: rgba(0, 0, 0, 0.7);
            text-align: center;

            &:hover {
                background-color: #111;
                color: #fff;
                text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
            }
        }
    }

    &:hover {
        .product-overlay {
            bottom: 0px;
        }
    }
}

/*Grid Columns*/
.grid-5-columns,
.grid-6-columns {
    .product {

        .product-title h3,
        .product-title h3 a,
        .product-price ins {
            font-size: 14px;
        }

        .product-reviews,
        .product-reviews a {
            font-size: 11px;
            float: left;
        }
    }
}

.grid-5-columns {
    .product {

        .product-reviews,
        .product-reviews a {
            float: right;
        }
    }
}

.shop-category {
    position: relative;
}

.shop-category-box {
    position: relative;
    margin-bottom: 20px;

    img {
        width: 100%;
    }

    .shop-category-box-title {
        background-color: rgba(255, 255, 255, 0.9);
        bottom: 10px;
        padding: 4px 0;
        position: absolute;
        text-align: center;
        width: 100%;
    }
}

.shop-category-box-title h6 {
    margin-bottom: 0;
}

// shop cart
.shop-cart th,
.shop-cart .table td {
    vertical-align: middle;
}

.shop-cart {
    .cart-product-remove {
        text-align: center;
    }

    .cart-product-thumbnail {
        >a {
            color: $color-body;
            float: left;
            margin-right: 12px;
        }

        img {
            max-width: 52px;
        }

        .cart-product-thumbnail-name {
            padding: 24px;
        }
    }
}

.cart-product-description {
    max-width: 200px;
    font-size: 12px;

    span {
        display: block;
    }
}

.cart-product-quantity {

    .minus,
    .plus {
        background-color: $color-white;
        border: 1px solid #eee;
        margin: 0 -4px;
        padding: 5px 12px;
    }

    .qty {
        border: 1px solid #eee;
        max-width: 40px;
        padding: 5px;
        text-align: center;
    }
}

.payment-method td {
    padding: 12px 20px !important;
}

// Shop product page
.product-page:not(.widget-shop) .product-title h3 {
    font-size: 28px;
    margin-bottom: 0;
}

.product-size {
    display: block;
    list-style: outside none none;
    margin: 0 20px 15px 0;
    overflow: hidden;
    padding-left: 0;

    li {
        float: left;
        margin: 0 -1px 0 0;

        label {
            span {
                cursor: pointer;
                display: block;
                font-size: 1.2em;
                font-weight: 400;
                height: 40px;
                line-height: 37px;
                margin: 0;
                text-align: center;
                transition: all 0.3s ease 0s;
                width: 40px;
            }

            input {
                left: -9000px;
                position: absolute;
            }

            span {
                border: 1px solid #eee;
                color: #909090;

                &:hover {
                    background-color: #eee;
                }
            }

            input:checked+span {
                background-color: #eee;
                border-color: #eee;
            }
        }
    }
}

.product {
    .comment {
        .product-rate {
            float: right;
        }
    }
}

.ajax-quick-view .product {
    margin-bottom: 0px;
    margin-top: 20px;
}

// Shop widget
.widget-shop {
    position: relative;

    .product {
        position: relative;
        border-bottom: 1px solid #eee;
        float: left;
        margin-bottom: 12px;
        min-height: 96px;
        padding-bottom: 12px;

        &:last-child {
            border-bottom: 0;
            margin-bottom: -4px;
            padding-bottom: 0;
        }

        .product-description {
            padding-top: 0px;
        }

        .product-image {
            float: left;
            padding-right: 10px;
            width: 26%;
        }

        .product-description {
            display: table-cell;
            padding-bottom: 0;
            vertical-align: top;
            width: 74%;
        }

        .product-title,
        .product-category {
            font-size: 12px;
            margin-bottom: 5px;
            margin-top: -8px;

            h3,
            h3 a {
                font-size: 15px;
                line-height: 18px;
            }
        }

        .product-category {
            margin-top: -6px;
        }

        .product-price {
            clear: both;
            float: none;
            margin-bottom: 0;
            margin-top: 0;
            text-align: left;

            ins {
                font-size: 14px;
                margin-bottom: 4px;
            }

            del {
                display: unset;
                font-size: 12px;
                margin: -8px 5px -8px 0;
            }
        }
    }
}

.widget-tweeter .list-tweets {
    padding-left: 0;
}

.widget-categories .list {
    padding: 0;
}

#footer .widget-categories a {
    color: #888;
}

/*
        .twitter-widget ul {
            margin: 0;
            padding: 0;
        }
        .twitter-widget li {
            list-style: none;
            clear: both;
        }
        .twitter-widget li img,
        .twitter-widget li .tweets_txt {
            float: left;
            margin-bottom: 10px;
        }
        .twitter-widget li img {
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            border-radius: 30px;
        }
        .twitter-widget li .tweets_txt {
            width: 350px;
            margin-left: 10px;
        }
        .twitter-widget li .tweets_txt span {
            font-style: italic;
            color: #666;
            display: block;
        }
*/
// shop box
.shop-promo-box {
    min-height: 360px;
    background-repeat: no-repeat;
    padding: 50px;
    border: 8px solid #eee;

    >h2 {
        font-weight: 800;
        margin-bottom: 0;
    }
}