
.lazy {
  transition: opacity 0.5s ease-in !important;
}

#mainMenu {
  nav>ul>li.current>a:after, nav>ul>li:hover>a:after, nav>ul>li:focus>a:after {
    width: 90%;
    left: 5%;
    background-color: $color-theme;
  }
  nav>ul>li>a:after {
    content: '';
    position: absolute;
    top: auto;
    bottom: -2px;
    left: 50%;
    width: 0;
    height: 2px;
    transition: all .3s ease;
  }
}

.form-control:focus,
.btn-shadow:focus {
  box-shadow: 0 0 0 0.25rem rgb(167 90 155 / 26%) !important;
}

.section-sl-1 {
  background: $color-theme;
  h2 {
    &:before {
      display: none;
    }
  }
}

.section-sl-counters {
  .counters {
    color: $dark;
  }
}

.section-sl-contact-items {
  padding-bottom: 0px;
  .list {
    padding-left: 0;
    li {
      i {
        padding-right: 12px;
      }
    }
  }
}
.section-sl-contact-form {
  padding-bottom: 0px;
}

.swal2-sl {
  .swal2-popup {
    .swal2-styled:focus,
    .swal2-close:focus{
      box-shadow: none !important;
    }
  }
  .swal2-styled.swal2-confirm {
      background-color: $color-theme;
  }
}

.sl-info-page {
  .container-card-category .cards-grid-v2 .category-item {
    margin-bottom: 0px;
  }
}
.section-sl-info-page-header {
  padding: 0px 0 0;
  @include breakpoint-md(max) {
    padding: 0px 0 0;
  }
  @include breakpoint-md2(max) {
    padding: 0px 0 0;
  }
  .col-text {
    padding-top: 100px;
    padding-bottom: 100px;
    @include breakpoint-md(max) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    @include breakpoint-md2(max) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
}


.text-captions>* {
  opacity: 0;
}
@include breakpoint-lg(max) {
  .text-captions {
    text-align: center;
  }
}

.section-video-bg.fullscreen {
  @include breakpoint-lg(max) {
    min-height: 50vh;
  }
  justify-content: center;
}

.section-sl-info-index {
  padding-bottom: 0px;
  &>.container {
    position: relative
  }
  .post-item-description {
    margin-top: 20px;
  }

  .row-with-content {
    padding: 190px 60px !important;
    @include breakpoint-lg(max) {
      padding: 210px 60px !important;
    }
    @include breakpoint-md2(max) {
      padding: 130px 60px !important;
    }
    @include breakpoint-md(max) {
      padding: 90px 30px !important;
    }
    @include breakpoint-sm(max) {
      padding: 65px 10px !important;
    }
  }
}

.sl-section-info-title {
  p {
    color: $color-white !important;
  }
}

.post-meta-category {
  a {
    color: $color-body;
    text-decoration: underline;
    i {
      padding-left: 10px;
      padding-right: 5px;
    }
  }
}

.section-sl-pagination {
  padding: 40px 0;
  .container {
    padding-left: 0;
    .pagination {
      @include breakpoint-md2(max) {
        justify-content: center;
      }
    }
  }
  .page-item {
    &.active {
      .page-link {
        background-color: #a75a9b !important;
        color: #fff !important;
        font-weight: bold;
      }
    }
    .page-link {
      padding: 0.7rem 1rem 0.5rem !important;
      @include breakpoint-md2(max) {
        padding: 1rem 1.4rem !important;
      }
      @include breakpoint-md(max) {
        padding: 1.2rem 1.6rem !important;
      }
    }
  }
}

.info-custom-breadcrumb {
  position: absolute;
  top: 5px;
  width: 100%;
  .breadcrumb {
    display: inline-block;
  }
  @include breakpoint-md2(max) {
    text-align: center!important;
  }
  @include breakpoint-md(max) {
    text-align: center!important;
  }
  @include breakpoint-sm(max) {
    text-align: center!important;
  }
}

.data-bg-video-cover-image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-position: center;
  z-index: -100;
}

.homepage-video-2-cols {
  @include breakpoint-lg2(max) {
    text-align: center;
    padding-right: 60px!important;
    padding-left: 60px!important;
    h4 {
      padding-bottom: 30px;
    }
  }
}

.html5video-wrap {
  position:relative;
  &.playing {
    .heading-text {
      opacity: 0.7;
      @include breakpoint-md(max) {
        opacity: 1 !important;
      }
    }
  }
  video {
    width: 100%;
  }
  .bg-overlay {
    cursor: pointer;
  }
  .playpause {
    margin-top: 30px;
    cursor: pointer;
    background-image:url('../../images2/play-button.svg');
    background-repeat:no-repeat;
    width:35%;
    height:35%;
    background-position: center;
    display: inline-block;
    @include breakpoint-md(max) {
      margin-top: 10px !important;
    }
  }
  .row {
    pointer-events: none;
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    @include breakpoint-md(max) {
      position: initial;
      top: initial;
      right: initial;
      bottom: initial;
      left: initial;
    }
    @include breakpoint-sm(max) {
      padding: 10px !important;
    }
    a {
      pointer-events: all;
    }
    .col-md-12 {
      text-align: center;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h3, p {
        text-shadow: 0px 0px 5px #000000;
        @include breakpoint-md(max) {
          text-shadow: none;
        }
      }
      h3 {
        &>span {
          border-radius: 6px;
          overflow: hidden;
          display: inline-block;
          padding-bottom: 3px;
          &>span {
            background: #00000055;
            padding: 10px;
            @include breakpoint-md(max) {
              background: none;
              color: #624b5e !important;
            }
          }
        }
      }
      p {
        @include breakpoint-sm(max) {
          display: none;
        }
        &>span {
          border-radius: 6px;
          overflow: hidden;
          display: inline-block;
          padding-bottom: 1px;
          &>span {
            background: #00000055;
            padding: 10px;
            @include breakpoint-md(max) {
              background: none;
              color: #624b5e !important;
            }
          }
        }
      }
    }
  }
  .playpause-sm-container {
    pointer-events: none;
    display: none;
    @include breakpoint-md(max) {
      display: flex !important;
    }
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .playpause {
      pointer-events: all;
    }
  }
  .sm-visible {
    display: none;
    @include breakpoint-md(max) {
      display: initial;
    }
  }
  .lg-visible {
    @include breakpoint-md(max) {
      display: none;
    }
  }
}
.homepage {
  .html5video-h3 {
    margin-bottom: 20px !important;
    @include breakpoint-md(max) {
      margin-bottom: 0px !important;
    }
  }
  .html5video-wrap {
    .row {
      @include breakpoint-md(max) {
        position: initial;
        top: initial;
        right: initial;
        bottom: initial;
        left: initial;
        h3 {
          margin-bottom: 0px !important;
        }
        padding: 0 0 0px !important;
        .m-t-40 {
          margin-top: 15px !important;
        }
      }
    }
  }
}
.page-content-container {
  ul {
    margin-left: 20px;
  }
}
.copyright-text {
  line-height: 15px;
}


.cards-h1 {
  font-size: 2rem !important;
  line-height: 1.2!important;;
  font-weight: 400!important;;
  margin-bottom: 0!important;;
}
.cards-h2 {
  font-size: 1.7rem!important;;
  line-height: 1.2!important;;
  font-weight: 400!important;;
}
.cards-breadcrumb {
  .breadcrumb {
    padding-left: 0;
    display: inline-block;
  }
}

.category-item-video {
  .video {
    width: 100%;
    height: auto;
  }
}

.container-card-category,
.container-card-show {

  @include breakpoint-lg(max) {
    max-width: 100%;
  }
  @include breakpoint-md1(max) {
    max-width: 100%;
    padding: 0;
    .row {
      .sidebar {
        padding-left: 45px;
        padding-right: 45px;
      }
      .content {
        .h1-submenu,
        .card-content,
        .cards-h2 {
          padding-left: 30px;
          padding-right: 30px;
        }
      }
    }
  }

  .cards-grid-v2 {
    margin-top: 30px;

    .category-item {
      margin-bottom: 50px;

      &.category-item-greeting {
        .greeting-row {
          background: #F7F9FB;
          color: #2d1529;
          padding: 25px 20px 25px;
          font-size: 16px;
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
          text-align: center;
        }
        .buttons-row {
          .card-buttons {
            .greeting-copy-btn {
              &.clicked {
                i.fa.fa-copy {
                  display: none;
                }
                i.fa.fa-check {
                  display: inline-block !important;
                }
              }
            }
            .card-make-btn {
              &.greeting-make-btn {
                line-height: 0 !important;
                display: inline-flex;
                i.fa {
                  line-height: 47px !important;
                }
                .text-g-1 {
                  display: inline-flex;
                  flex-direction: column;
                  padding-top: 8px;
                  .text-g-2 {
                    line-height: 16px !important;
                  }
                  .text-g-3 {
                    line-height: 16px !important;
                    font-size: 13px;
                    padding-top: 0;
                    text-transform: lowercase;
                    font-weight: normal;
                  }
                }

                @include breakpoint-sm(max) {
                  height: 48px !important;
                  line-height: 47px !important;
                  font-size: 12px !important;
                  padding-left: 8px !important;
                  padding-right: 12px !important;
                  letter-spacing: 2px;
                }
              }
            }
          }
        }
      }

      &.category-item-image {
        .buttons-row {
          padding-top: 10px;
        }
      }

      .title-row {
        //border: 2px solid #e8e8e8;
        padding: 15px 20px 12px;
        border-bottom: none;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        background: #F7F9FB;

        @include breakpoint-sm(max) {
          padding: 10px 20px;
        }

        a,
        a:hover,
        a:visited{
          font-size: 16px;
          display: inline-block;
          color: #93728e;
          font-weight: bold !important;

          @include breakpoint-sm(max) {
            font-size: 15px;
          }
        }
      }
      .video-row {
        position: relative;
        background: #F7F9FB;
        .video {
          background: #F7F9FB;
        }
      }
      .image-row {
        img {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }

  .buttons-row {
    padding: 0 15px 10px;
    border-bottom: none;
    background: #F7F9FB;

    &.background-none {
      background: none;
    }

    @include breakpoint-sm2(max) {
      text-align: center;
    }

    .row {
      padding: 0;
    }
  }
  .card-buttons-col {
    @include breakpoint-md(max) {
      text-align: center !important;
      padding: 10px 0;
    }
    @include breakpoint-sm(max) {
      padding-bottom: 7px;
    }

    .card-btn {
      margin-top: 10px;
      margin-bottom: 10px;
      -webkit-border-image: none;
      font-size: 14px !important;
      height: 48px !important;
      letter-spacing: 2px;
      line-height: 47px !important;
      padding: 0 20px 0 15px !important;

      @include breakpoint-md(max) {
        margin-top: 0;
        margin-bottom: 0;
      }
      @include breakpoint-sm(max) {
        height: 36px !important;
        line-height: 35px !important;
        font-size: 12px !important;
        padding-left: 8px !important;
        padding-right: 12px !important;
      }

      &.card-download-btn {
        background: #a9a9a900 !important;
        color: #5d5d5d;
        white-space: nowrap;
        &:hover {
          background-color: #42484d !important;
          color: #ffffff;
        }
        &:active,
        &:focus {
          background: #a9a9a900 !important;
          color: #5d5d5d !important;
        }
      }
      &.card-make-btn {
        //float: right;

        @include breakpoint-sm2(max) {
          float:none;
        }
      }
      &.add-name-btn {
        margin: 10px 0;
      }
    }
    .name-field-and-button {
      .name-field-container-0 {
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        background: #fff;
        border: 2px solid #a75a9b;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-left: 15px;
        border-radius: 5px;

        @include breakpoint-md(max) {
          justify-content: center;
        }

        input.name-field {
          max-width: 160px;
          outline: none;
          border: none;
          font-size: 23px;
          padding-top: 6px;
          background: transparent;
          text-align: center;
          padding-bottom: 9px !important;
          @include breakpoint-sm(max) {
            font-size: 17px;
            min-height: 30px;
            padding-bottom: 6px !important;
            max-width: 140px;
          }
        }

        .card-make-btn {
          margin-top: -2px !important;
          margin-bottom: -2px !important;
          margin-right: -2px;
        }
      }
    }
    &.card-buttons-col-info {
      margin-top: 20px;
      margin-bottom: 20px;
      .name-field-and-button {
        .name-field-container-0 {
          border-radius: 35px;
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }
}
.container-card-show {
  .card-buttons {
    margin-bottom: 20px !important;
  }
}
.show2 .homepage-video-slider-section {
  .container {
    @include breakpoint-md2(max) {
      padding: 0;
      max-width: initial !important;
    }
  }
}
.show2-buttons {
  .card-buttons-col {
    @include breakpoint-md(max) {
      text-align: center !important;
      padding: 10px 0;
    }

    .card-btn {
      margin-top: 0!important;
      margin-bottom: 6px!important;
      font-size: 16px!important;
      height: 58px!important;
      letter-spacing: 2px!important;
      line-height: 56px!important;
      padding: 0 40px!important;

      @include breakpoint-md(max) {
        margin-top: 0!important;
        margin-bottom: 6px!important;
        font-size: 16px!important;
        height: 58px!important;
        letter-spacing: 2px!important;
        line-height: 56px!important;
        padding: 0 40px!important;
      }
      @include breakpoint-sm(max) {
        margin-top: 0!important;
        margin-bottom: 6px!important;
        font-size: 16px!important;
        height: 58px!important;
        letter-spacing: 2px!important;
        line-height: 56px!important;
        padding: 0 40px!important;
      }
    }

    .share-buttons {
      display: inline-flex;
      align-items: center;
      gap: 20px;
      @include breakpoint-md(max) {
        display: block;
      }
      .send-text {
        @include breakpoint-md(max) {
          padding: 0px 0 10px;
        }
      }
      .yandex-buttons {

      }
    }
  }
}
//.container-card-show {
//  .card-content {
//
//  }
//  .card-buttons {
//    padding: 10px 0 20px;
//    @include breakpoint-md(max) {
//      padding: 20px 0;
//    }
//    .card-btn {
//      //padding: 0 40px 0 25px !important;
//      //@include breakpoint-lg(max) {
//      //  font-size: 16px !important;
//      //  height: 58px !important;
//      //  letter-spacing: 2px;
//      //  line-height: 56px !important;
//      //  padding: 0 20px 0 15px !important;
//      //}
//      //@include breakpoint-md(max) {
//      font-size: 14px !important;
//      height: 48px !important;
//      letter-spacing: 2px;
//      line-height: 48px !important;
//      padding: 0 20px 0 15px !important;
//      //}
//    }
//    .card-buttons-col {
//      @include breakpoint-md(max) {
//        text-align: center !important;
//        padding: 10px 0;
//      }
//    }
//  }
//}


.cards-grid {
  margin-right: -20px;
  .grid-item {
    padding: 0 20px 20px 0;
    @include breakpoint-md(max) {
      width: 100%;
      margin-bottom: 10px;
    }
    .preview-container {
      display: inline-block;
      width: 100%;
      height: 240px;
      overflow: hidden;
      position: relative;
      &.video {
        .play-icon {
          position: absolute;
          z-index: 10;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 6rem;
          height: 6rem;
          background: url('../../images2/play-button2.svg');
          border-radius: 50%;
          -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
          -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
          box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
          @include breakpoint-lg(max) {
            width: 8rem;
            height: 8rem;
          }
          @include breakpoint-md2(max) {
            width: 8rem;
            height: 8rem;
          }
          @include breakpoint-md(max) {
            width: 9rem;
            height: 9rem;
          }
          @include breakpoint-sm(max) {
            width: 20vw;
            height: 20vw;
          }
        }
        .time {
          background: #000000bd;
          color: #fff;
          position: absolute;
          bottom: 10px;
          left: 10px;
          padding: 0 10px 1px;
          border-radius: 8px;
          font-size: 12px;
          border: 1px solid #b1b1b1a3;
        }
        .badge-video-card {
          position: absolute;
          top: -10px;
          left: -10px;
          background: $color-theme;
          border: 10px solid #fff;
          border-radius: 13px;
          font-size: 12px;
          font-weight: bold;
          padding: 0 10px 1px;
          color: #fff;
        }
      }
      @include breakpoint-xl(max) {
        height: 200px;
      }
      @include breakpoint-lg(max) {
        height: 310px;
      }
      @include breakpoint-md(max) {
        height: auto;
        overflow: initial;
        margin-bottom: 8px;
      }

      .preview-img {
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;

        @include breakpoint-md(max) {
          object-fit: initial;
          height: auto;
          max-width: 100%;
          min-height: initial;
          min-width: initial;
        }
      }
    }

    .preview-title-container {
      height: 48px;
      display: -webkit-box;
      //max-width: 100%;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      @include breakpoint-md(max) {
        height: initial;
        max-width: initial;
        display: initial;
        overflow: initial;
        -webkit-line-clamp: initial;
        -webkit-box-orient: initial;
      }
      text-decoration: underline;
    }
  }
}

.page-card-show {
  .video-card {
    .video {
      outline: none;
      width: 100%;
      height: auto;
    }
    .wrapper{
      position: relative;
    }
  }
}
.play-btn-overlay {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  @media (hover: none){
    /**
    touch devices
    на мобильный убираем кнопку play т.к. она и так есть во встроенных плеерах
    */
    visibility: hidden;
  }
  transform: translate(-50%, -50%);
  i.fa {
    color: #fff;
    font-size: min(20vw, 150px);
    opacity: 0.6;
    cursor: pointer;
    text-shadow: 5px 4px 4px rgba(0,0,0,0.32);
  }
}



.sidebar .widget.widget-card-categories:last-child {
  @include breakpoint-md2(max) {
    margin-bottom: 20px;
  }
}
.cards-left-menu {
  &.collapse {
    display: initial;
  }
  @include breakpoint-md2(max) {
    &.collapse:not(.show) {
      display: none;
    }
  }
  list-style-type: none;
  &>li {
    padding-left: 0 !important;
  }
  ul {
    list-style-type: none;
  }
  li {
    padding: 0px 0px 0 12px;
    .category-title {
      position: relative;
      cursor: pointer;
      display: inline-block;
      width: 100%;

      border-bottom: 1px solid #dbdbdb;
      padding: 7px 4px 7px 8px;
      color: #624b5e;
      &:hover {
        color: #a75a9b
      }
      &[aria-expanded=true] {
        font-weight: bold;
      }
      .active-arrow {
        display: none;
      }
      &.active {
        font-weight: bold;
        color: #a75a9b;
        border-left: 3px solid #a75a9b;
        //padding-left: 4px;
        .active-arrow {
          display: inline-block;
          float: right;
          padding-top: 4px;
        }
      }
      &.active-parent {
        font-weight: bold;
      }

      &.with-icon {
        padding-left: 29px;
        .category-title-icon {
          width: 18px;
          height: 18px;
          display: inline-block;
          background-repeat: no-repeat;
          margin: 0;
          background-size: cover;
          position: absolute;
          top: 8px;
          left: 6px;
        }
      }
    }
  }

  span[role="button"] {
    margin-left: 7px;
  }
  .sl-menu-arrow-custom {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 1px 12px 3px 5px;
    &:hover {
      background: #f9ecf7;
      border: 1px solid #e5e5e5;
    }
    &:before {
      margin-left: 5px;
      line-height: 11px;
      width: 12px;
      height: 12px;
      display: inline-block;
      content: "\e930";
      font-family: "inspiro-icons";
      opacity: 0.6;
      color: #262626;
      transform: rotate(90deg);
    }
  }
}

.cards-top-menu {
  span[role="button"] {
    margin-left: 15px;
  }
  .sl-menu-arrow-custom {
    border-radius: 5px;
    padding: 2px 12px 3px 5px;
    border: 1px solid #e5e5e5;
    &:hover {
      background: #f9ecf7;
      border: 1px solid #e5e5e5;
    }
    &:before {
      margin-left: 5px;
      line-height: 11px;
      width: 12px;
      height: 12px;
      display: inline-block;
      content: "\e930";
      font-family: "inspiro-icons";
      opacity: 0.6;
      color: #262626;
      transform: rotate(90deg);
    }
  }

  a.with-icon {
    position: relative;
    .category-title-icon {
      width: 18px;
      height: 18px;
      display: inline-block;
      background-repeat: no-repeat;
      margin: 0;
      background-size: cover;
      position: absolute;
      top: 8px;
      left: -5px;

      @include breakpoint-lg(max) {
        top: 9px;
        left: -22px;
      }
    }
  }
  a.holiday {
    position: relative;
    padding-left: 30px !important;
    .tab {
      --tab-edge-distance: 9px;
      background-color: #e9e9e9;
      width: 30px;
      aspect-ratio: 1 / 0.75;
      border-radius: 4px;
      place-items: center;
      display: inline-block;
      padding-left: 1px;
      position: absolute;
      top: 7px;
      left: -5px;

      .hole {
        position: absolute;
        background-color: white;
        width: 3px;
        height: 5px;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        top: 0;
      }
      .left {
        left: var(--tab-edge-distance);
      }
      .right {
        right: var(--tab-edge-distance);
      }
      .number {
        font-size: 9px;
        color: #a75a9b;
        font-weight: bold;
        padding-top: 5px;
      }

      &.active {
        background-color: #a75a9b;
        .number {
          color: #fff;
        }
      }
    }
  }
}

.left-categories-toggle-button {
  display: none;
  @include breakpoint-md2(max) {
    display: initial;
    //font-weight: normal!important;
    border-width: 1px!important;
    text-transform: none!important;
  }
}

.sl-menu-arrow-custom-button {
  display: none;
  @include breakpoint-md2(max) {
    display: initial;
  }
}

.categories-children {
  margin-bottom: 15px;
  .item {
    display: inline-block;
    border: 1px solid #a75a9b;
    border-radius: 5px;
    padding: 7px 8px 8px;
    line-height: initial;
    margin: 5px 5px;
    position: relative;
    &.bg-1 {
      background-color: #ffefef;
    }
    &.bg-2 {
      background-color: #f6f4ff;
    }
    &.bg-3 {
      background-color: #e6fffd;
    }
    &.bg-4 {
      background-color: #e6ffec;
    }
    &.bg-5 {
      background-color: #fffbe6;
    }
    &:hover{
      border-color: #812472;
      color: #fff !important;
      background: $color-theme;
    }
    .holiday-date {
      position: absolute;
      top: -9px;
      right: -1px;
      font-size: 10px;
      letter-spacing: 0;
      background: #a75a9b;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 3px;
      color: #fff;
      padding: 2px 5px 2px 6px;
      border-top-right-radius: 5px;
    }
  }
  .item-active {
    display: inline-block;
    border: 1px solid #812472;
    border-radius: 5px;
    padding: 7px 30px 8px 8px;
    line-height: initial;
    margin: 5px 5px;
    color: #fff !important;
    background: $color-theme;
    position: relative;
    .item-active-close {
      margin-bottom: 0;
      position: absolute;
      right: 0;
      top: 0;
      padding: 8px 10px;
      i {
        color: #fff;
        font-size: 1em;
      }
    }
    .holiday-date {
      position: absolute;
      top: -9px;
      right: -1px;
      font-size: 10px;
      letter-spacing: 0;
      background: #a75a9b;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 3px;
      color: #fff;
      padding: 2px 5px 2px 6px;
      border-top-right-radius: 5px;
    }
  }
  .items {
    padding-bottom: 10px;
  }

  &.holidays-child-categories {
    .item, .item-active {
      margin-bottom: 7px;
    }
  }
}

.categories-by-year-list {
  margin-bottom: 30px;
  a {
    display: inline-block;
    border: 1px solid #a75a9b;
    border-radius: 5px;
    padding: 5px 8px 6px;
    line-height: initial;
    margin: 5px 5px;
    &:hover {
      border-color: #812472;
      color: #fff !important;
      background: $color-theme;
    }
  }
  .items {
    overflow-x: scroll;
    white-space: nowrap;
    padding-bottom: 10px;
  }
}

.categories-by-name-list {
  .items {
    padding-top: 6px;
  }
  .char {
    font-weight: bold;
    font-size: 14px;
    padding-right: 7px;
  }
  a {
    padding: 0 8px 0 0;
    text-decoration: underline;
  }
  //.items {
  //  overflow-x: scroll;
  //  white-space: nowrap;
  //  padding-bottom: 10px;
  //}
}

.btn:not(.close):not(.mfp-close):not(.btn-creative):not(.btn-slide).btn-lg-2 {
  @include breakpoint-lg(max) {
    font-size: 16px !important;
    height: 52px !important;
    line-height: 50px !important;
    padding: 0 52px !important;
  }
  @include breakpoint-sm(max) {
    font-size: 14px !important;
    height: 46px !important;
    line-height: 44px !important;
    padding: 0 24px !important;
  }
}

.homepage {

  @include breakpoint-lg(max) {
    .btn:not(.close):not(.mfp-close):not(.btn-creative):not(.btn-slide).btn-lg {
      font-size: 16px;
      height: 52px;
      line-height: 50px;
    }
  }
  @include breakpoint-sm(max) {
    .btn:not(.close):not(.mfp-close):not(.btn-creative):not(.btn-slide).btn-lg {
      font-size: 16px;
      height: auto;
      line-height: 24px;
      padding: 9px 17px;
    }
  }

  &.mainMenu-open #header {
    &:not(.sticky-active) {
      .header-inner {
        #mainMenu-trigger {
          .lines-button {
            .lines {
              background-color: #111 !important;

              &:before, &:after {
                background-color: #111 !important;
              }
            }
          }
        }
      }
    }
  }

  #header {
    position: absolute;
    top: 0;
    .link-price.menu-item-only-desktop {
      display: initial !important;
    }
    &:not(.sticky-active) {
      .header-inner {
        background-color: #ffffff00;
        padding-top: 30px;
        #logo {
          filter: brightness(0) invert(1);
        }
        #mainMenu-trigger {
          .lines-button {
            .lines {
              background-color: #ffffff !important;
              &:before, &:after {
                background-color: #ffffff !important;
              }
            }
          }
        }
        @include breakpoint-lg(min) {
          #mainMenu nav > ul > li > a {
            color: #ffffff;
            margin-bottom: 0;
          }
          #mainMenu nav > ul > li:hover > a:after {
            background-color: #ffffff;
          }
          #mainMenu nav > ul > li {
            &.link-home {
              display: none;
            }
            &.link-make {
              a {
                background: none !important;
              }
            }
          }
        }
      }
    }
  }
  .top-video-bg-container {
    //min-height: 990px!important;
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;

    @include breakpoint-md(max) {
      h4 {
        font-size: 18px;
      }
      p {
        font-size: 14px;
      }
    }

    .row {
      @include breakpoint-lg(max) {
        margin-top: 100px !important;
      }
      @include breakpoint-sm(max) {
        margin-top: 150px !important;
      }
    }

    &:before {
      background-image: url('../../images2/pattern.png');
      background-repeat: repeat;
      background-size: auto;
      content: '';
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      z-index: 2;
    }

    .video {
      z-index: -1!important;
      backface-visibility: hidden;
      position: absolute;
      right: 0;
      bottom: 0;
      top: 0;
      width: 100%;
      height: 100%;
      //-webkit-background-size: 100% 100%;
      //background-size: 100% 100%;
      background-color: #d2bacd;
      background-position: center center;
      -webkit-background-size: contain;
      background-size: contain;
      -o-object-fit: cover;
      object-fit: cover;
    }
    .container {
      z-index: 100;
    }

    .left-text {
      @include breakpoint-lg(max) {
        text-align: center;
      }
    }
  }

  .sl-testimonial {
    .flickity-button {
      background: #ffffff8a;
      @include breakpoint-md(max) {
        &.previous {
          margin-left: -30px;
        }
        &.next {
          margin-right: -30px;
        }
      }
    }
    .polo-carousel-item {
      padding: 0 30px;
      @include breakpoint-md(max) {
        padding: 0 14px;
      }
    }
    .testimonial-item {
      background-color: #ffffffa1;
      border: none;
      border-radius: 10px;

      & > span ~ span {
        font-weight: normal;
        color: #373737;
      }

      @include breakpoint-md(max) {
        padding: 10px;

        & > p, & > span {
          font-size: 12px;
        }
        & > span ~ span {
          font-size: 11px;
        }
      }
    }
  }

  .homepage-123 {
    .row {
      &>div:not(:first-child) {
        h2 {
          @include breakpoint-md2(max) {
            margin-top: 80px;
          }
          @include breakpoint-md(max) {
            margin-top: 50px;
          }
        }
      }
    }
    h2 {
      background: url(../../images2/homepage-circle.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      line-height: 165px;
      padding-bottom: 10px;
      margin-bottom: 40px;
      color: #834a7a !important;
      a {
        color: #834a7a !important;
      }

      @include breakpoint-lg(max) {
        line-height: 140px !important;
      }
      @include breakpoint-md(max) {
        line-height: 80px !important;
        padding-bottom: 5px;
        margin-bottom: 20px;
      }
    }
    h3 {
      a {
        color: #624b5e !important;
      }
    }
  }

  .homepage-video-slider-section {
    .container {
      @include breakpoint-md2(max) {
        padding: 0;
        max-width: initial !important;
      }
    }
  }

  .section-sl-free-cards {
    .cards-grid {
      .grid-item {
        @include breakpoint-xl(max) {
          width: 33.33%;
          .preview-container {
            height: 270px;
          }
        }
        @include breakpoint-lg(max) {
          width: 33.33%;
        }
        @include breakpoint-md2(max) {
          width: 50%;
          .preview-container {
            height: 310px;
          }
        }
        @include breakpoint-md(max) {
          width: 100%;
          .preview-container {
            height: auto;
          }
        }
      }
    }
  }

  .top-text-row.row {
    margin-top: 150px;
    margin-bottom: 50px;
    @include breakpoint-lg(max) {
      margin-top: 150px !important;
    }
    @include breakpoint-md(max) {
      margin-bottom: 10px !important;
    }
  }
  .video-navigation-row.row {
    margin-bottom: 50px;
    margin-top: 0 !important;
    @include breakpoint-md(max) {
      margin-bottom: 20px !important;
    }

    .video-navigation {
      column-count: 2;
      column-gap: 30px;
      @include breakpoint-md2(max) {
        column-count: 1;
      }
      .video-navigation-block {
        background: #fff7;
        border-radius: 10px;
        padding: 20px 30px 20px 25px;
        margin-bottom: 35px;
        @include breakpoint-lg(max) {
          padding: 15px 20px 15px 15px;
          margin-bottom: 27px;
        }
        @include breakpoint-md(max) {
          padding: 15px 20px 15px 15px;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.video-navigation-block {
  a {
    &.level-1 {
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      //letter-spacing: -0.7px;
      margin-bottom: 16px;
      display: inline-block;
      background: #7d266f8c;
      border-radius: 10px;
      padding: 9px 15px 10px;
      line-height: initial;
      margin-left: 5px;
      &:focus {
        color: #fff;
      }
      &:hover {
        background: #7d266fcc;
        color: #fff;
      }
      @include breakpoint-lg(max) {
        font-size: 16px;
        margin-bottom: 12px;
        padding: 7px 17px 8px;
      }
    }
    &.level-2 {
      display: inline-block;
      border: 1px solid #a75a9b;
      border-radius: 10px;
      padding: 8px 15px 9px;
      line-height: initial;
      margin: 5px 5px;
      background: #ffffffd9;
      position: relative;
      &:hover,
      &:active,
      &:focus {
        background: #fff1fd;
      }
      @include breakpoint-lg(max) {
        font-size: 14px;
        padding: 7px 12px 8px;
        margin: 4px 4px;
      }
      @include breakpoint-md(max) {
        font-size: 14px;
        padding: 7px 9px 8px;
        margin: 3px 3px;
      }
      .holiday-date {
        position: absolute;
        top: -7px;
        right: -1px;
        font-size: 10px;
        letter-spacing: 0;
        background: #a75a9b;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 3px;
        color: #fff;
        padding: 1px 5px 1px 6px;
        border-top-right-radius: 5px;
      }
    }
  }
}

.card-show-add-categories-banner-block {
  .categories-banner-block {
    margin-bottom: 50px;
  }
}
.container-card-category {
  .card-show-add-categories-banner-block {
    .categories-banner-block {
      margin-bottom: 20px;
    }
  }
}
.categories-banner-block {
  background: #f3f3f3;
  border-radius: 10px;
  padding: 20px 15px 15px;
  margin-bottom: 30px;

  .categories-children {
    margin-bottom: 0;
    .items {
      padding-bottom: 0;
    }
  }

  a {
    &.level-1 {
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 16px;
      display: inline-block;
      background: #7d266f8c;
      border-radius: 10px;
      padding: 9px 15px 10px;
      line-height: initial;
      margin-left: 5px;
      &:focus {
        color: #fff;
      }
      &:hover {
        background: #7d266fcc;
        color: #fff;
      }
      @include breakpoint-lg(max) {
        font-size: 14px;
        margin-bottom: 12px;
        padding: 7px 17px 8px;
      }
    }
  }
}

.footer-search-button {
  font-size: 12px !important;
  line-height: 14px !important;
  padding: 12px 18px !important;
}

.page-card-category,
.page-card-show {
  @include breakpoint-md(max) {
    #page-title {
      background: none !important;
      padding: 0 !important;
      height: 1px;
      .bg-overlay {
        opacity: 1;
        background: #d3b4ce;
      }
    }
    #page-content {
      padding-top: 15px !important;
    }
    .cards-h1 {
      font-size: 1.6rem !important;
    }
    .cards-breadcrumb {
      .breadcrumb {
        margin-bottom: 0 !important;
      }
    }
    .categories-children {
      margin-bottom: 0 !important;
      margin-left: -25px;
      margin-right: -25px;
      .item {
        padding: 7px 6px 8px;
        margin: 3px 3px;
      }
      &.holidays-child-categories {
        .item, .item-active {
          margin-bottom: 7px;
        }
      }
    }
    .categories-banner-block {
      .categories-children {
        margin-left: 0;
        margin-right: 0;
      }
    }
    .cards-grid-v2 {
      margin-top: 15px !important;
    }
  }
}

.page-holidays-calendar {
  h2 {
    font-size: 1.6em;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .day-container {
    display: flex;
    align-content: flex-start;
    margin-bottom: 20px;
    align-items: center;
    .day {
      padding-right: 20px;
      .tab {
        --tab-edge-distance: 15px;
        position: relative;
        background-color: #eaebed;
        width: 50px;
        aspect-ratio: 1 / 0.9;
        border-radius: 0.5rem;
        display: grid;
        place-items: center;

        .hole {
          position: absolute;
          background-color: white;
          width: 5px;
          height: 8px;
          border-bottom-left-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          top: 0;
        }
        .left {
          left: var(--tab-edge-distance);
        }
        .right {
          right: var(--tab-edge-distance);
        }
        .number {
          font-size: 1.5rem;
          font-weight: bold;
          color: #a75a9b;
          padding-top: 5px;
        }

        &.active {
          background-color: #a75a9b;
          .number {
            color: #fff;
          }
        }
      }
    }
    .holidays-container {
      padding-top: 5px;
      p.holiday {
        font-size: 14px !important;
        margin-bottom: 0px;
        &:not(:last-child) {
          margin-bottom: 3px;
        }
        a {
          font-size: 14px !important;
        }
      }
    }
  }
}

.cards-left-menu li .category-title.holiday {
  display: flex;
  align-items: center;
  .tab {
    --tab-edge-distance: 9px;
    position: relative;
    background-color: #e9e9e9;
    width: 30px;
    aspect-ratio: 1 / 0.75;
    border-radius: 4px;
    place-items: center;
    display: inline-block;
    margin-right: 10px;
    margin-top: -2px;
    padding-left: 1px;
    .hole {
      position: absolute;
      background-color: white;
      width: 3px;
      height: 5px;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      top: 0;
    }
    .left {
      left: var(--tab-edge-distance);
    }
    .right {
      right: var(--tab-edge-distance);
    }
    .number {
      font-size: 9px;
      color: #a75a9b;
      font-weight: bold;
      padding-top: 2px;
    }
    &.active {
      background-color: #a75a9b;
      .number {
        color: #fff;
      }
    }
  }
  .category-name {

  }
}

.ya-share2 {
  height: 24px;
  @media (hover: none){
    /** touch devices */
    height: 32px;
  }
}
.main-loader-text-container {
  position: relative;
  z-index: 30;
  margin-top: 80px;
  .main-loader-text {
    text-align: center; display: block; padding: 180px 0 10px; font-size: 18px; color: #a75a9b; font-weight: bold; animation: color-change 2s infinite;
  }
}