/* ----------------------------------------------------------------
Lables & Badgets
-----------------------------------------------------------------*/
.badge {
    font-weight: 600;
}

.a.badge {
    color: $color-white;
}

.badge-pill {
    padding: 0.4em .8em;
    border-radius: 10rem;
}

.badge-primary {
    background-color: $color-theme;
}