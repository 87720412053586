/*generated with Input range slider CSS style generator (version 20211225)
https://toughengineer.github.io/demo/slider-styler*/
input[type=range].styled-slider {
  height: 1em;
  -webkit-appearance: none;
}

/*progress support*/
input[type=range].styled-slider.slider-progress {
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 1em + var(--ratio) * (100% - 1em));
}

input[type=range].styled-slider:focus {
  outline: none;
}

/*webkit*/
input[type=range].styled-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 1em;
  background: #595959;
  border: none;
  box-shadow: 0 0 2px black;
  margin-top: calc(0.6em * 0.5 - 1em * 0.5);
  cursor: pointer;
}

input[type=range].styled-slider::-webkit-slider-runnable-track {
  height: 0.6em;
  border: none;
  border-radius: 0.5em;
  background: #dadada;
  box-shadow: none;
  cursor: pointer;
}

input[type=range].styled-slider::-webkit-slider-thumb:hover {
  background: #595959;
}

input[type=range].styled-slider:hover::-webkit-slider-runnable-track {
  background: #dadada;
}

input[type=range].styled-slider::-webkit-slider-thumb:active {
  background: #595959;
}

input[type=range].styled-slider:active::-webkit-slider-runnable-track {
  background: #dadada;
}

input[type=range].styled-slider.slider-progress::-webkit-slider-runnable-track {
  background: linear-gradient(#595959,#595959) 0/var(--sx) 100% no-repeat, #dadada;
}

input[type=range].styled-slider.slider-progress:hover::-webkit-slider-runnable-track {
  background: linear-gradient(#595959,#595959) 0/var(--sx) 100% no-repeat, #dadada;
}

input[type=range].styled-slider.slider-progress:active::-webkit-slider-runnable-track {
  background: linear-gradient(#595959,#595959) 0/var(--sx) 100% no-repeat, #dadada;
}

/*mozilla*/
input[type=range].styled-slider::-moz-range-thumb {
  width: 1em;
  height: 1em;
  border-radius: 1em;
  background: #595959;
  border: none;
  box-shadow: 0 0 2px black;
}

input[type=range].styled-slider::-moz-range-track {
  height: 0.6em;
  border: none;
  border-radius: 0.5em;
  background: #dadada;
  box-shadow: none;
}

input[type=range].styled-slider::-moz-range-thumb:hover {
  background: #595959;
}

input[type=range].styled-slider:hover::-moz-range-track {
  background: #dadada;
}

input[type=range].styled-slider::-moz-range-thumb:active {
  background: #595959;
}

input[type=range].styled-slider:active::-moz-range-track {
  background: #dadada;
}

input[type=range].styled-slider.slider-progress::-moz-range-track {
  background: linear-gradient(#595959,#595959) 0/var(--sx) 100% no-repeat, #dadada;
}

input[type=range].styled-slider.slider-progress:hover::-moz-range-track {
  background: linear-gradient(#595959,#595959) 0/var(--sx) 100% no-repeat, #dadada;
}

input[type=range].styled-slider.slider-progress:active::-moz-range-track {
  background: linear-gradient(#595959,#595959) 0/var(--sx) 100% no-repeat, #dadada;
}

/*ms*/
input[type=range].styled-slider::-ms-fill-upper {
  background: transparent;
  border-color: transparent;
}

input[type=range].styled-slider::-ms-fill-lower {
  background: transparent;
  border-color: transparent;
}

input[type=range].styled-slider::-ms-thumb {
  width: 1em;
  height: 1em;
  border-radius: 1em;
  background: #595959;
  border: none;
  box-shadow: 0 0 2px black;
  margin-top: 0;
  box-sizing: border-box;
}

input[type=range].styled-slider::-ms-track {
  height: 0.6em;
  border-radius: 0.5em;
  background: #dadada;
  border: none;
  box-shadow: none;
  box-sizing: border-box;
}

input[type=range].styled-slider::-ms-thumb:hover {
  background: #595959;
}

input[type=range].styled-slider:hover::-ms-track {
  background: #dadada;
}

input[type=range].styled-slider::-ms-thumb:active {
  background: #595959;
}

input[type=range].styled-slider:active::-ms-track {
  background: #dadada;
}

input[type=range].styled-slider.slider-progress::-ms-fill-lower {
  height: 0.6em;
  border-radius: 0.5em 0 0 0.5em;
  margin: 0;
  background: #595959;
  border: none;
  border-right-width: 0;
}

input[type=range].styled-slider.slider-progress:hover::-ms-fill-lower {
  background: #595959;
}

input[type=range].styled-slider.slider-progress:active::-ms-fill-lower {
  background: #595959;
}