@import "../variables";
@import "../mixins";


//Default #2250fc
//$color-theme: rgba(167, 90, 155, 0.71);

a {
    color: $color-theme;
}

/* ----------------------------------------------------------------
	Typography
-----------------------------------------------------------------*/
a:not(.btn):not(.badge):hover, a:not(.btn):not(.badge):focus, a:not(.btn):not(.badge):active {
	color: $color-theme;
}
/* ----------------------------------------------------------------
	Accordion
-----------------------------------------------------------------*/
.accordion { 
    &.accordion-theme {
        .ac-item {
            background-color: $color-theme;
        }
    }
}

/* ----------------------------------------------------------------
	Blockquotes
-----------------------------------------------------------------*/
.blockquote {
    border-left: 3px solid $color-theme;
}

.blockquote-color {
    background-color: $color-theme;
}

/* ----------------------------------------------------------------------
	Buttons
-------------------------------------------------------------------------*/
button.btn, 
.btn:not(.close):not(.mfp-close), a.btn:not([href]):not([tabindex]) {
    background-color: $color-theme;
    border-color: $color-theme;

    &:hover,
    &:focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        background-color: darken($color-theme, 10%);
        border-color: darken($color-theme, 10%);
    }

    &.btn-outline {
        border-color: darken($color-theme, 10%);
        color: $color-theme;

        &:hover,
        &:focus,
        &:active,
        &.active {
            background-color: $color-theme;
            border-color: $color-theme;
        }
    }

    &.btn-primary {
        background-color: $color-theme;
        border-color: $color-theme;

        &:hover,
        &:focus,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active {
            background-color: $color-theme;
            border-color: $color-theme;
        }
    }

    &.btn-icon-holder {
        &.btn-outline {
            &:before {
                border-left-color: $color-theme;
            }
        }
    }
}

/* ----------------------------------------------------------------------
    call-to-action (call to action)
-------------------------------------------------------------------------*/
.call-to-action {
    &.call-to-action-colored {
        background-color: $color-theme;
    }
}

/* ----------------------------------------------------------------
Carousels & Slider
-----------------------------------------------------------------*/

.inspiro-slider {
    .flickity-button:hover {
	    background-color: $color-theme;
    }
}

.carousel {
    .flickity-button {
        &:hover {
            background-color: $color-theme;
        }
    }
}

/* ----------------------------------------------------------------
    Dropcat & Highlight
-----------------------------------------------------------------*/
.dropcap {
    &.dropcap-colored {
        background-color: $color-theme;
    }
}

.highlight {
    &.highlight-colored {
        background-color: $color-theme;
    }
}

/* ----------------------------------------------------------------------
Forms
-------------------------------------------------------------------------*/
.form-control,
input,
select {
    &:focus {
        border-color: $color-theme;
    }
}

/* ----------------------------------------------------------------------
	Headings
-------------------------------------------------------------------------*/
.heading-text {
    &.heading-section {
        >h2 {
            &:before {
                background-color: $color-theme;
            }
        }
    }
}

.heading-creative {
    strong {
        color: $color-theme;
    }
}

/* ----------------------------------------------------------------------
	Icon Box
-------------------------------------------------------------------------*/
.icon-box {
    &.effect {
        &.dark {
            .icon i:after {
                box-shado: 0 0 0 3px $color-theme;
            }

            .icon i:hover,
            &:hover .icon i {
                background-color: $color-theme;
            }
        }
    }
}

.text-box {
    &.text-box-light {
        &:hover {
            background: $color-theme;
        }
    }
}

.feature-box {
    &:hover {

        h2,
        h3,
        h4 {
            color: $color-theme;
        }
    }
}

/* ----------------------------------------------------------------
Lables & Badgets
-----------------------------------------------------------------*/
.badge-primary {
    background-color: $color-theme;
}

/* ----------------------------------------------------------------------
	Light Box
-------------------------------------------------------------------------*/
.mfp-ajax-holder .mfp-close:hover {
    background: $color-theme;
}

/* ----------------------------------------------------------------------
	Lists & Bullet Lists
-------------------------------------------------------------------------*/
.list-colored {
    li a {
        color: $color-theme;
    }
}

.list-legend {
    li {
        span {
            background-color: $color-theme;
        }
    }
}

.list-icon {

    &.list-icon-colored li:before,
    &.icon-list-colored i {
        color: $color-theme;
    }
}

.list-group-item.active {
    background-color: $color-theme;
    border: $color-theme;
}

/* ----------------------------------------------------------------------
	Pagination & Pager
-------------------------------------------------------------------------*/
.post-navigation {
    a:hover {
        color: $color-theme;
    }
}

/* ----------------------------------------------------------------------
	Pricing Table
-------------------------------------------------------------------------*/
.pricing-table {
    &.colored {
        .plan-header {
            background-color: $color-theme;
        }
    }
}

/* ----------------------------------------------------------------------
	Progress Bar
-------------------------------------------------------------------------*/
.p-progress-bar,
.progress-bar {
    background-color: $color-theme;
}

/*--------------------------------------------------------
     Tabs
---------------------------------------------------------*/
.tabs {
    .nav-tabs {
        .nav-link.active {
            color: $color-theme;
            border-bottom: 2px solid $color-theme;
        }
    }

    &.tabs-vertical {
        .nav-link.active {
            color: $color-theme;
            border-right: 2px solid $color-theme;
        }
    }
}

/* ----------------------------------------------------------------
	Date picker
-----------------------------------------------------------------*/
.datepicker {
    tbody tr>td {
        &.day {

            &.today.active,
            &.active,
            &.selected {
                background: $color-theme !important;

                &:hover {
                    background: $color-theme;
                }
            }

            &.range {
                &.today {
                    background: $color-theme;
                }
            }
        }

        span.month {
            &.active {
                background: $color-theme;

                &:hover {
                    background: $color-theme;
                }
            }
        }
    }
}

.datetimepicker tbody tr>td.day.today,
.datetimepicker table tr td.active:active,
.datetimepicker table tr td.active:hover:active,
.datetimepicker table tr td.active.disabled:active,
.datetimepicker table tr td.active.disabled:hover:active,
.datetimepicker table tr td.active.active,
.datetimepicker table tr td.active:hover.active,
.datetimepicker table tr td.active.disabled.active,
.datetimepicker table tr td.active.disabled:hover.active,
.datetimepicker table tr td span.active:active,
.datetimepicker table tr td span.active:hover:active,
.datetimepicker table tr td span.active.disabled:active,
.datetimepicker table tr td span.active.disabled:hover:active,
.datetimepicker table tr td span.active.active,
.datetimepicker table tr td span.active:hover.active,
.datetimepicker table tr td span.active.disabled.active,
.datetimepicker table tr td span.active.disabled:hover.active {
    background: $color-theme !important;
}

.datetimepicker table tr>td.today:hover,
.datetimepicker table tr>td.today:hover:hover,
.datetimepicker table tr>td.today.disabled:hover,
.datetimepicker table tr>td.today.disabled:hover:hover,
.datetimepicker table tr>td.today:active,
.datetimepicker table tr>td.today:hover:active,
.datetimepicker table tr>td.today.disabled:active,
.datetimepicker table tr>td.today.disabled:hover:active,
.datetimepicker table tr>td.today.active,
.datetimepicker table tr>td.today:hover.active,
.datetimepicker table tr>td.today.disabled.active,
.datetimepicker table tr>td.today.disabled:hover.active,
.datetimepicker table tr>td.today.disabled,
.datetimepicker table tr>td.today:hover.disabled,
.datetimepicker table tr>td.today.disabled.disabled,
.datetimepicker table tr>td.today.disabled:hover.disabled,
.datetimepicker table tr>td.today[disabled],
.datetimepicker table tr>td.today:hover[disabled],
.datetimepicker table tr>td.today.disabled[disabled],
.datetimepicker table tr>td.today.disabled:hover[disabled] {
    background: $color-theme !important;
}

.daterangepicker {
    .daterangepicker_input {
        .input-mini {

            &.active,
            &:focus {
                border: 1px solid $color-theme;
            }
        }
    }

    tbody td {
        &.today {
            background: $color-theme;
        }

        &.active,
        &.in-range,
        &.active:hover,
        &.in-range:hover {
            background: $color-theme;
        }
    }

    select {
        &.ampmselect {

            &.active,
            &:focus {
                border: 1px solid $color-theme;
            }
        }

        &.hourselect {

            &.active,
            &:focus {
                border: 1px solid $color-theme;
            }
        }

        &.minuteselect {

            &.active,
            &:focus {
                border: 1px solid $color-theme;
            }
        }

        &.monthselect {

            &.active,
            &:focus {
                border: 1px solid $color-theme;
            }
        }

        &.yearselect {

            &.active,
            &:focus {
                border: 1px solid $color-theme;
            }
        }
    }

    .calendar-time i {
        color: #b2b1c5;
    }

    .ranges li {
        &.active {
            background: $color-theme;
            border: 1px solid $color-theme;
        }
    }
}

/* ----------------------------------------------------------------
	Date range picker
-----------------------------------------------------------------*/
.daterangepicker {

    td.active:not(.off),
    td.active:hover,
    .active.end-date.in-range {
        background: $color-theme !important;
    }
}

input.daterange:after,
input.dates:after {
    background: $color-theme;
}

/* ----------------------------------------------------------------
	Bootstrap Switch
-----------------------------------------------------------------*/
.bootstrap-switch {

    .bootstrap-switch-on,
    .bootstrap-switch-handle-on,
    .bootstrap-switch-handle-off {
        background-color: $color-theme;
        border-color: $color-theme;
    }
}

.p-radio {
    &:checked+.p-radio-style {
        &:before {
            border: 1px solid $color-theme;
            background: $color-theme;
        }
    }
}

.p-checkbox {
    &:checked+.p-checkbox-style {
        &:before {
            border: $color-theme;
            background: $color-theme;
        }
    }
}

.p-switch {
    >input[type="checkbox"] {
        &:checked+.p-switch-style {
            background-color: $color-theme;
        }
    }
}

/* ----------------------------------------------------------------
	Ion Range Sliders
-----------------------------------------------------------------*/
.irs-bar {
    background: $color-theme;
}

.irs-from,
.irs-to,
.irs-single {
    background: $color-theme;
}

.irs-from:after,
.irs-to:after,
.irs-single:after {
    border-top-color: $color-theme;
}

/* ----------------------------------------------------------------
	Blog
-----------------------------------------------------------------*/
.post-item {

    .post-image,
    .post-slider,
    .post-video,
    .post-audio,
    .post-quote-img {
        .post-meta-category {
            background-color: $color-theme;
        }
    }

    .post-quote-img {
        .post-meta-category {
            color: $color-theme;

            a {
                color: $color-theme;
            }
        }
    }

    /*Types*/
    &.quote {
        .post-item-wrap {
            background-color: $color-theme;
        }
    }
}

.post-author {
    a {
        color: $color-theme;
    }
}

/* ----------------------------------------------------------------
	Grid Filter
-----------------------------------------------------------------*/
.grid-filter {
    li {

        &:hover,
        &.active {
            a {
                background-color: $color-theme;
            }
        }
    }

    &.gf-outline {
        li {

            &:hover,
            &.active {
                a {
                    border-color: $color-theme;
                    color: $color-theme;
                }
            }
        }
    }

    &.gf-lines {
        li {

            &:hover,
            &.active {
                a {
                    color: $color-theme;

                    &:after {
                        background-color: $color-theme;
                    }
                }
            }
        }
    }

    &.gf-classic {
        li {

            &:hover,
            &.active {
                a {
                    background-color: $color-theme;
                }
            }
        }
    }

    &.gf-default {
        li {

            &:hover,
            &.active {
                a {
                    color: $color-theme;
                }
            }
        }
    }
}

/*------------------------------------------------------
     Comments
------------------------------------------------------*/
.comments {
    .comment_number {
        span {
            color: $color-theme;
        }
    }
}

.respond-comment {
    span {
        color: $color-theme;
    }
}

/*------------------------------------------------------
     Extras
------------------------------------------------------*/
.item-link {
    color: $color-theme;

    &:hover {
        color: $color-theme;
    }
}

.nav-link {
    color: $color-theme;
}

.background-colored {
    background-color: $color-theme;
}

/* ----------------------------------------------------------------
Scroll top
-----------------------------------------------------------------*/
#scrollTop {

    &:after,
    &:before {
        background-color: $color-theme;
    }
}

.fc-event-primary {
    &.fc-event {
        background-color: $color-theme;
        border-color: $color-theme;
    }
}

.icon-set-container {
    .row>[class^="col-"] {
        color: $color-theme;

        &:hover {
            background-color: $color-theme;

            .icon-holder {
                color: $color-theme;
            }
        }
    }
}

/* ----------------------------------------------------------------
    Forum
-----------------------------------------------------------------*/
.forum {
    thead tr {
        >th {
            background-color: $color-theme;
        }
    }
}

/* ----------------------------------------------------------------
    Header
-----------------------------------------------------------------*/
#header {
    &.header-modern:not(.header-active) {
        &.header-colored {

            .header-inner,
            #header-wrap {
                background-color: $color-theme;
            }
        }
    }

    &.header-colored {

        .header-inner,
        #header-wrap {
            background-color: $color-theme;
        }
    }

    /* ----------------------------------------------------------------
Main Menu
-----------------------------------------------------------------*/
    #mainMenu {
        nav {
            >ul {
                >li {

                    &.hover-active,
                    &.current,
                    &:hover,
                    &:focus {

                        >a,
                        >span {
                            color: $color-theme;
                        }
                    }

                    .dropdown-menu {
                        >li {

                            >a,
                            >span,
                            [class*="col-"]>ul>li>a {

                                &:focus,
                                &:hover,
                                &:active {
                                    color: $color-theme;
                                }
                            }

                            &.current,
                            &:hover,
                            &:focus,
                            &:active,
                            &.hover-active {

                                >a,
                                >span {
                                    color: $color-theme;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.menu-rounded {
            nav {
                >ul {
                    >li {

                        &.current,
                        &:hover,
                        &:focus {
                            >a {
                                background-color: $color-theme;
                            }
                        }
                    }
                }
            }
        }

        &.menu-outline {
            nav {
                >ul {
                    >li {

                        &.current,
                        &:hover,
                        &:focus {
                            >a {
                                color: $color-theme;
                                border-color: $color-theme;
                            }
                        }
                    }
                }
            }
        }

        &.menu-lines {
            nav {
                >ul {
                    >li {

                        &.current,
                        &:hover,
                        &:focus {
                            >a {
                                color: $color-theme;

                                &:after {
                                    background-color: $color-theme;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.menu-hover-background {
            nav {
                >ul {
                    >li {

                        &.current,
                        &:hover,
                        &:focus {
                            background-color: $color-theme;
                        }
                    }
                }
            }
        }
    }

    #shopping-cart {
        >a {
            .shopping-cart-items {
                background: $color-theme;
            }
        }
    }
}

/* ----------------------------------------------------------------
Dots Menu
-----------------------------------------------------------------*/
#dotsMenu {
    ul {
        li {
            &:hover {
                background-color: $color-theme;
            }

            &.current {
                background-color: $color-theme;
            }

            a {

                &:hover,
                &.active {
                    &:before {
                        background-color: $color-theme;
                    }
                }
            }
        }
    }
}

/*Menu Dark*/
#mainMenu.dark:not(.light):not(.menu-rounded) {
    nav {
        >ul {
            >li {
                .dropdown-menu {
                    >li {

                        >a,
                        >span,
                        [class*="col-"]>ul>li>a {
                            &:hover {
                                color: $color-theme;
                            }
                        }
                    }
                }

                &.current,
                &:hover,
                &:focus,
                &.hover-active {

                    >a,
                    >span {
                        color: $color-theme;
                    }
                }
            }
        }
    }
}

.page-menu {
    nav {
        >ul {
            >li {

                &:hover,
                &.active {
                    >a {
                        color: $color-theme;
                    }
                }
            }
        }
    }

    &.menu-rounded {
        nav {
            >ul {
                >li {

                    &:hover,
                    &.active {
                        >a {
                            background-color: $color-theme;
                        }
                    }
                }
            }
        }
    }

    &.menu-outline {
        nav {
            >ul {
                >li {

                    &:hover,
                    &.active {
                        >a {
                            border-color: $color-theme;
                            color: $color-theme;
                        }
                    }
                }
            }
        }
    }

    &.menu-lines {
        nav {
            >ul {
                >li {

                    &:hover,
                    &.active {
                        >a {
                            color: $color-theme;

                            &:after {
                                background-color: $color-theme;
                            }
                        }
                    }
                }
            }
        }
    }

    &.menu-solid {
        nav {
            >ul {
                >li {

                    &:hover,
                    &.active {
                        >a {
                            background-color: $color-theme;
                        }
                    }
                }
            }
        }
    }
}

@include breakpoint-lg(max) {
    .page-menu {

        &.menu-rounded,
        &.menu-outline,
        &.menu-lines,
        &.menu-solid,
        &.menu-light,
        &.menu-creative, 
        &.inverted {
            nav {
                >ul {
                    >li {

                        &:hover,
                        &.active {
                            >a {
                                color: $color-theme;
                            }
                        }
                    }
                }
            }
        }
    }
}

#search {
    .btn-search-close {
        background-color: $color-theme;
    }
}

/* ----------------------------------------------------------------
Topbar
-----------------------------------------------------------------*/
#topbar {

    &.topbar-colored,
    &.topbar-colored.dark {
        background-color: $color-theme;
    }
}

/* ----------------------------------------------------------------
Helpers
-----------------------------------------------------------------*/
.text-theme {
    color: $color-theme !important;
}

/* ----------------------------------------------------------------
Portfolio
-----------------------------------------------------------------*/
.portfolio-item,
.grid-item {

    .portfolio-description,
    .grid-description,
    .portfolio-links {
        a {
            &:hover {
                i {
                    background-color: $color-theme;
                    border: 1px solid $color-theme;
                }
            }
        }
    }
}

/* ----------------------------------------------------------------
	Widgets
-----------------------------------------------------------------*/
.widget-tweeter li a,
.widget-twitter li a {
    color: $color-theme;
}

.tags a {

    &:hover,
    &:focus,
    &:active,
    &.active {
        background-color: $color-theme;
        border-color: $color-theme;
    }
}


