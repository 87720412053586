// Мобильная версия внутри vk.com
body.mobile_web,
body.mobile_ipad,
body.mobile_iphone,
body.mobile_android {
  .page-make-section {
    padding-top: 0 !important;
  }
  .make-app {
    .tabs-top-sticky-empty {
      top: 0 !important;
    }

    .nav {
      top: 0 !important;
      padding-top: 60px !important;
      padding-bottom: 10px;
      //&.sticky-active:not(.on-boarding-active) {
      //  padding-top: 68px;
      //  top: -12px;
      //  padding-bottom: 2px;
      //}
    }
    button.btn.btn-primary.button-tab-my-video-absolute {
      top: 25%;
    }

    .music-container {
      .music-player {
        top: 129px !important;
      }
      .music-player-sticky-empty {
        top: -85px;
      }
    }
  }
  &.ReactModal__Body--open {
    .ril__closeButton {
      margin-top: 80px !important;
    }
  }
  .sl-swal {
    .swal2-popup {
      @include breakpoint-md(max) {
        width: 85% !important;
        height: 80vh !important;
        min-height: 410px !important;
      }
      @include breakpoint-sm(max) {
        width: 100% !important;
        height: 80vh !important;
        min-height: 410px !important;
      }
    }
  }
}

.vk-terms-checkbox {
  text-decoration: underline !important;
  cursor: pointer !important;
}

.sl-swal {
  &.sl-swal-vk-terms-popup {
    .swal2-popup {
      width: 70% !important;
      height: 80vh !important;

      .swal2-html-container {
        margin-right: 40px !important;
      }
    }
  }
}

.checkout-modal__container {
  margin-top: 80px !important;
}