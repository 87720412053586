.ril-outer {
  .lightboxCustomContent {
    pointer-events: none;
    & > .slidesPopupItem {
      &.text {
        /* для того, чтобы текстовые фото не blur'ились, а то из-за antialiasing'а совсем мутные были */
        img {
          image-rendering: optimizeSpeed;             /*                     */
          image-rendering: -moz-crisp-edges;          /* Firefox             */
          image-rendering: -o-crisp-edges;            /* Opera               */
          image-rendering: -webkit-optimize-contrast; /* Chrome (and Safari) */
          image-rendering: optimize-contrast;         /* CSS3 Proposed       */
        }

        //.controls {
        //  margin-left: 0;
        //  .control {
        //    &.delete {
        //      left: 50%;
        //      -webkit-transform: translateX(-50%);
        //      transform: translateX(-50%);
        //      margin-left: 0;
        //    }
        //  }
        //}
      }
      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 80% !important;
        @include breakpoint-md(max) {
          width: 100% !important;
        }
        height: initial !important;
        max-width: 900px !important;
        @include breakpoint-md(max) {
          max-width: 100% !important;
        }
        max-height: 80% !important;
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
      }

      .controls {
        pointer-events: all;
        position: relative;
        top: 10%;
        margin-left: -20px;

        .control {
          margin-left: 20px;
          background: #ffffffaa;
          border-radius: 10px;
          position: absolute;
          opacity: 0.8;
          cursor: pointer;
          -webkit-transition: all 0.2s linear;
          -moz-transition: all 0.2s linear;
          -o-transition: all 0.2s linear;
          transition: all 0.2s linear;

          &:hover {
            background: #ffffffee;
            opacity: 1;

            &.delete {
              color: #9d0000;
            }

            &.rotate {
              color: #229d00;
            }

            &.edit {
              color: #229d00;
            }
          }
        }

        .fa {
          font-size: 22px;
        }

        .rotate {
          bottom: 10%;
          right: 10%;
          padding: 15px 14px 10px 15px;
        }

        .edit {
          bottom: 10%;
          right: 10%;
          padding: 15px 14px 10px 15px;
        }

        .delete {
          bottom: 10%;
          left: 10%;
          padding: 15px 14px 10px 15px;
        }
      }

      .upload-error-container {
        width: 100%;
        height: 100%;
        .upload-error {
          width: 100%;
          height: 100%;
          background-image:url('../../../images2/upload_failed.png');
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 10px 10px 60px 10px;
          text-align: center;
          .local-file-name,
          .upload-error-text {
            overflow-wrap: break-word;
          }
          .upload-error-text {
            font-weight: bold;
            padding-bottom: 10px;
          }
          .local-file-name {
            max-height: 70px;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 15px;
          }
        }
      }
    }
  }
}