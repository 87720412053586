
.tinkoffPayRow {
  display:block;
  margin:1%;
  width:160px;
}

.make-app {
  .payment-container {
    padding: 0 10px;

    .row {
      margin-left: 0px;
      margin-right: 0px;
    }

    .video-container {
      width: 640px;
      padding-bottom: 50px;
      @include breakpoint-md(max) {
        width: 100%;
      }
      .btn-back-to-slides {
        margin-top: 25px;
      }
      .video-container-2 {
        position: relative;
        .video-overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(204, 251, 241, 0.4);
        }
        .stub-generation-in-progress {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          .video {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 10;
          }
          .inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #ffffffcc;
            z-index: 11;

            .preview-countdown-circle-timer {
              margin-bottom: 30px;
              @include breakpoint-sm(max) {
                margin-bottom: 5px;
              }
              position: relative;
              .text-container {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                left: 0px;
                top: 0px;
                width: 100%;
                height: 100%;
                text-align: center;

                .ball {
                  transform: scale(0.7);
                  width: 26px;
                  height: 60px;
                  margin-bottom: 20px;
                  margin-top: -20px;

                  @include breakpoint-sm(max) {
                    transform: scale(0.5);
                    width: initial;
                    height: initial;
                    margin-bottom: initial;
                    margin-top: initial;
                    padding-left: 8px;
                  }
                }
                .value {
                  font-size: 42px;
                  margin-bottom: 15px;
                  color: #a75a9b;
                  margin-top: 23px;
                  @include breakpoint-md(max) {
                    font-size: 32px;
                    margin-bottom: 15px;
                  }
                  @include breakpoint-sm(max) {
                    margin-top: 5px;
                    font-size: 20px;
                    margin-bottom: 2px;
                  }
                }
                .almost-ready,
                .seconds {
                  font-size: 12px;
                  color: #a75a9b;
                  @include breakpoint-sm(max) {
                    font-size: 10px;
                  }
                }
                .almost-ready {
                  @include breakpoint-sm(max) {
                    display: none;
                  }
                }
              }
            }
            .text-1 {
              color: #a75a9b;
              font-size: 20px;
              @include breakpoint-sm(max) {
                font-size: 16px;
                margin-bottom: 5px;
              }
            }
            .text-2 {
              color: #a75a9b;
              font-size: 14px;
              @include breakpoint-sm(max) {
                margin-bottom: 5px;
              }
            }
            .text-vk {
              &:hover {
                text-decoration: underline;
                cursor: pointer;
              }
              text-decoration: underline !important;
              font-weight: bold !important;
              @include breakpoint-sm3(max) {
                display: none;
              }
              span {
                color: #a75a9b;
              }
            }
          }
        }
        .play-btn-overlay {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          i.fa {
            color: #fff;
            font-size: min(20vw, 150px);
            opacity: 0.6;
            cursor: pointer;
            text-shadow: 5px 4px 4px rgba(0,0,0,0.32);
          }
        }
      }

      .controls {
        display: flex;
        .stretch {
          flex-grow: 1;
        }
        .play-btn {
          padding: 5px 15px;
          cursor: pointer;
        }
        .time-label {
          padding: 5px 5px;
          font-size: 0.9em;
        }
        .volume-control {
          padding: 5px 15px;
          display: flex;
          .volume-btn {
            padding: 0 15px;
            cursor: pointer;
          }
          .volume-range {
            padding: 0;
            margin: 5px 0;
            min-height: auto;
            width: 70px;
          }
        }
        .fullscreen-btn {
          padding: 5px 15px;
        }
      }
    }
    .stub-height {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      img {
        max-width: 100%;
        width: 100%;
        height: auto;
      }
    }
    .preview-header {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 7px;
    }
    .loaderContainer {
      position: relative;
      height: 80px;
    }

    .horizontal-slider {
      height: 15px;
      &.all-chunks-ready {
        .track.track-2 {
          display: none !important;
        }
      }
      .track {
        top: 0;
        bottom: 0;
        border-radius: 999px;
        &.track-0 {
          background: #a75a9b;
        }
        &.track-1 {
          background: #c399bc;
        }
        &.track-2 {
          margin-left: 12px;
          .progress {
            height: 100% !important;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 999px;
            border-top-right-radius: 999px;
          }
        }
      }
      .thumb {
        height: 25px;
        line-height: 25px;
        width: 25px;
        margin-top: -4px;
        text-align: center;
        background-color: #bd71b1;
        color: rgb(255, 255, 255);
        border-radius: 50%;
        border: 2px solid #d096c7;
        -webkit-box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.60);
        -moz-box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.60);
        box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.60);
        cursor: grab;
        outline: none;
        &.thumb-1 {
          display: none;
          height: 0;
          width: 0;
        }
      }
    }

    .payment-block {
      .price-block {
        display: flex;
        margin-bottom: 1rem;
        align-items: center;
        .price {
          display: inline-block;
          margin-bottom: 0;
          white-space: nowrap;
        }
        .promo-code-form {
          display: inline-block;
          padding-left: 20px;
          width: 130px;
          min-width: 130px;
          @include breakpoint-lg(max) {
            min-width: 118px;
          }
          @include breakpoint-sm(max) {
            min-width: 107px;
          }
          .form-control {
            font-size: 14px;
            border: 1px solid #c9acc4;
            color: #723d6b;
            &::placeholder {
              color: #838383;
            }
            padding: 10px 2px 10px 14px;
            @include breakpoint-sm(max) {
              font-size: 13px;
              padding: 10px 2px 10px 7px;
            }
          }
          .mb-3 {
            margin-bottom: 0 !important;
          }
        }
        .promo-code-how-to {
          text-decoration: underline;
          font-size: 12px;
          display: inline-block;
          line-height: 13px;
          margin-left: 20px;
          color: #a75a9b;
          cursor: pointer;
        }
        .promo-loader {
          width: 40px;
          height: 40px;
          display: inline-block;
        }
      }
      .email-form-block {
        padding: 20px 0 60px 0;

        .form-label {
          font-weight: bold;
          font-size: 14px;
        }
        .form-control {
          font-size: 16px;
          border: 1px solid #c9acc4;
          color: #723d6b;
          &::placeholder {
            color: #b6b6b6;
          }
        }
      }
    }

    .payment-form-container {
      display: none;
    }

    .wait-all-photos-load {
      .sl-loader-ball {
        height: 20px;
        .inner-container {
          .progress {
            width: 100%;
          }
        }
      }
    }

    .info-c {
      margin-top: -64px;
      .info-c-btn-final {
        float: right;
        margin-top: 18px;
      }
      .info-c-preview-id {
        clear: both;
        float: right;
        display: flex;
        .preview-id-input {
          width: 280px;
        }
      }
    }
  }
}

// tinkoff
.t-wrapper {
  input:not([type=checkbox]):not([type=radio]), select {
    line-height: initial;
    padding: initial;
    min-height: initial;
    font-size: initial;
  }
}