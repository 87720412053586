.sl-swal-vk-allow-notifications {
  .swal2-popup {
    height: 450px !important;
    .text {
      font-size: 14px;
    }
  }
}

.sl-swal-reorder-slides-info {
  .swal2-popup {
    max-height: 390px !important;
    max-width: 500px !important;
    .swal2-styled.swal2-confirm {
      background-color: $color-theme;
    }
    .swal2-html-container {
      >div {
        overflow: hidden;
      }
      .reorderImage {
        width: 90%;
        height: auto;
      }
      p {
        font-size: 1em;
        line-height: 1.4;
      }
    }
  }
}

.sl-swal-add-slide
{
  .swal2-popup {
    min-height: 560px;
    display: flex !important;
    flex-direction: column;
    .swal2-html-container {
      height: 100%;
    }
    .swal2-close {
      position: absolute;
      right: 2px;
    }
  }
  .add-slides-popup {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;

    .tab-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      .tab-pane.active {
        display: flex;
        flex-direction: column;
        flex-grow: 5;

        .tab-text {
          flex-grow: 5;
          margin: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .max-length-indicator {
            font-size: 14px;
          }
          .add-text-btn {
            margin-top: 25px;
            margin-bottom: 12px;
          }

          .textarea-container {
            width: 100%;
            height: 100%;
            text-align: center;
            padding: 20px;
            border: 2px solid #dbdbdb;
            position: relative;

            textarea {
              color: $color-body;
              outline: none;
              border: none;
              padding: 20px;
              width: 100%;
              resize: none;
            }

            textarea,
            .placeholder,
            .textarea-fit-div{
              position: absolute;
              top: 0;
              bottom: 0;
              right: 0;
              left: 0;
              z-index: 30;
              padding: 20px 45px;
              text-align: center;
              font-size: 80px;
              overflow: hidden;
              white-space: normal !important;
              word-wrap: normal !important;
              word-break: keep-all !important;

            }
            .textarea-fit-div {
              z-index: 25;
              opacity: 0;
            }
            .placeholder {
              font-size: min(12vw, 9vh, 50px);
              color: #dedede;
              z-index: 35;
              pointer-events: none;
              display: table;
              height: 100%;
              width: 100%;

              span {
                display: table-cell;
                text-align: center;
                vertical-align: middle;
              }
            }
          }
        }
        .tab-upload {
          flex-grow: 5;
          padding: 10px;
          margin: 10px;
          border: 3px dashed #CCC;

          .upload__image-wrapper {
            height: 100%;
          }

          &:hover {
            border-color: #a75a9b;

            .upload-circle {
              background-color: #a75a9b !important;
            }
          }

          .drop-area {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;

            &.is-dragging {
              background: #eee;
            }

            .upload-circle {
              margin: 30px 0;
              height: 80px;
              width: 80px;
              background-color: #dbbbd6;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              //&:hover {
              //  background-color: #a75a9b;
              //}
              i {
                color: #fff !important;
                font-size: 24px;
              }
            }

            .hint {
              margin-bottom: 40px;
            }

            .select-photos-btn {
              margin-bottom: 20px;
            }

            .hint2 {
              margin-top: 40px;
              font-size: 14px;
            }
          }
        }
      }
    }

    .nav {
      flex-wrap: nowrap;
      justify-content: center;
      border-bottom: none;

      .nav-item {
        margin-left: 15px;
        margin-right: 15px;
        button {
          outline: none !important;
          background: none;
          border: none;
          border-bottom: 2px solid transparent;
          position: relative;
          padding-top: 40px;
          color: #626262;
          &.active {
            border-bottom: 2px solid $active-color;
            color: $active-color;
          }
        }
        .tab-item {
          &:before {
            color: #787878 !important;
            font-size: 24px;
            font-weight: 900;
            font-family: 'Font Awesome 6 Free';
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
          }
          &.active {
            &:before {
              color: $active-color !important;
            }
          }
        }
        &:not(:first-of-type) {
          .tab-item {
            margin-left: 30px;
            @include breakpoint-sm(max) {
              margin-left: -10px;
            }
          }
        }
        .tab-photo {
          &:before {
            font-weight: normal;
            content: "\f1c5";
          }
        }
        .tab-text {
          &:before {
            content: "\f031";
          }
        }
      }
    }

    &.edit-slide-popup {
      .nav {
        display: none !important;
      }
    }
  }
}