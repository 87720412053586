.sl-loader-ball {
  opacity: 1;
  z-index: 20;
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  pointer-events: all;

  .inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    .ball {
      position: relative;
    }

    .progress {
      display: inline-block;
      width: 50%;
      height: 4px;
      border-radius: 2px;
      overflow: hidden;
      background: #d1d1d1;

      .inner {
        display: block;
        background: #a75a9b;
        width: 0;
        height: 100%;
        -webkit-transition: width 1s linear;
        -moz-transition: width 1s linear;
        -o-transition: width 1s linear;
        transition: width 1s linear;
      }
    }
  }

  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
.blur5 {
  filter: blur(5px);
}

.sl-loader-ball.loaded {
  opacity: 0;
  pointer-events: none;
}