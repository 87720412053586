.make-app {
  .music-container {
    padding: 0 10px;
    position: relative;
    h5 {
      border-bottom: 1px solid #ccc;
    }
    .hr {
      padding-top: 10px;
      padding-bottom: 20px;
      height: 2px;
      background-color: #CCCCCC;
    }
    .music-player-sticky-empty {
      position: absolute;
      top: -65px;
    }
    .music-player {
      position: sticky;
      &.on-boarding-active {
        position: unset;
      }
      top: 83px;
      padding-bottom: 10px;
      &:not(.on-boarding-active):before {
        z-index: -100;
        content: "";
        background-color: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        right: calc((100vw - 100%) / -2);
        left: calc((100vw - 100%) / -2);
      }
      &.sticky-active:not(.on-boarding-active) {
        z-index: 99;
        filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.3));
        margin-left: -10px;
        margin-right: -10px;
        background: #fff;
        padding-left: 10px;
        padding-right: 10px;
        > div:not(.song-title) {
          height: 40px !important;
        }
      }
      .song-title {
        padding-left: 24px;
        color: $color-theme;
      }
      audio {
        outline: none;
        box-shadow: none;
      }
    }
    .music-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;

      .music-item {
        padding: 10px 12px;
        margin-bottom: 2px;
        width: 33%;
        cursor: pointer;
        position: relative;
        &.active {
          background-color: $color-theme;
          color: #fff !important;
          &:hover {
            background-color: #8f4e86;
          }
        }
        &:hover {
          background-color: #efe7ee;
        }
        &.uploading {
          background-color: #efefef7a;
          .title1,
          .title2 {
            color: #bbb;
          }
          .sl-loader-ball {
            right: 10px;
            left: 10px;
            top: initial;
            bottom: 7px;
            height: 4px;
            .progress {
              width: 100%;
            }
          }
        }
        &.upload-error {
          background-color: #efefef7a;
          cursor: initial;
          .title1,
          .title2 {
            color: #ff8787;
          }
        }
        @include breakpoint-md(max) {
          width: 50%;
        }
        @include breakpoint-sm(max) {
          width: 100%;
        }
        .inner {
          display: flex;
          align-items: center;
          .info {
            flex-grow: 1;
            text-overflow:ellipsis;
            white-space:nowrap;
            overflow:hidden;
            .title1 {
              font-weight: bold;
            }
          }
          .play-btn {
            padding: 0 10px 0 0;
          }
          .actions {
            padding-right: 15px;
          }
          .delete {
            margin-left: 5px;
            padding: 0 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
}